<div class="dialog-container">
    <mat-toolbar class="customize">
        Copy Assignment
    </mat-toolbar>
    <form class="p-3 dialog-actions" [formGroup]="assignmentForm" (ngSubmit)="CreateAssignment(assignmentForm.value)">
        <div class="section"><strong class="size-M">From Assignment :- </strong>{{assignment_name | titlecase }}</div>
        <div class="section d-flex justify-content-between align-items-center">
            <div class="w-50">
                <div class="form-group">
                    <strong class="size-M">New Assignment Name</strong>
                </div>
                <div class="form-group">
                    <input class="form-control val" placeholder="New assignment name" formControlName="new_assignment_name" maxlength="56">
                    <mat-error class="valerror" *ngIf="assignmentName.hasError('required') && assignmentName.dirty">Assignment Name is required!</mat-error>
                    <mat-error class="valerror" *ngIf="assignmentName.hasError('pattern')">Special Characters not Allowed!</mat-error>
                    <mat-error class="valerror" *ngIf="assignmentName.hasError('maxlength') && !assignmentName.hasError('pattern')"> Maximum 55 Characters Allowed!</mat-error>
                    <mat-error class="valerror" *ngIf="assignmentName.hasError('minlength') && !assignmentName.hasError('pattern')"> Minimum 3 Characters Allowed!</mat-error>
                </div>
            </div>
            <div class="w-50 ml-3">
                <div class="form-group">
                    <strong class="size-M">Year</strong>
                </div>
                <div class="form-group">
                    <mat-select class="form-control" placeholder="Select year" formControlName="year" (openedChange)="closeMatSelectEvent($event)" >
                        <div class="search_bar"><input [(ngModel)]="search_year" [ngModelOptions]="{standalone: true}"
                            placeholder="Search..." (keydown)="$event.stopPropagation()" /></div>
                        <mat-option *ngFor="let year of yearList | searchFilter :'val':search_year:yearList" [value]="year.val">
                            {{year.val}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="Year.hasError('required') && Year.dirty">Year is required!</mat-error>
                 </div>
            </div>
        </div>
        <div class="section" formArrayName="language">
            <div class="form-group copy-assignment-label">
                <strong class="size-M">Language</strong>
                <strong class="size-M">Assignment Label</strong>
            </div>
            <div class="mb-1" *ngFor="let languagevalues of language_valueset;let kk = index; let last = last">
                <ng-container [formGroupName]="kk">
                    <div class="form-container">
                        <mat-select class="form-select-l" formControlName="language">
                            <mat-option *ngFor="let language of languages" value="{{language.id}}">{{language.name}}</mat-option>
                        </mat-select>
                        <input class="input-sm" formControlName="assignment_label">
                        <div class="addbtn">
                            <div *ngIf="last" (click)="addRow()" class="border iconTick-1">
                                <mat-icon>add</mat-icon>
                            </div>
                            <div (click)="removeRow(kk)" class="border iconTick-1">
                                <mat-icon>remove</mat-icon>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="section">
            <div class="form-group">
                <strong class="size-M">With Property Details : </strong>
                <mat-checkbox formControlName="with_property_details">
                </mat-checkbox>
            </div>
        </div>
        <div class="section">
            <div class="form-group">
                <strong class="size-M">With User List : </strong>
                <mat-checkbox formControlName="with_user_list">
                </mat-checkbox>
            </div>
        </div>
        <div class="section grid-2" fxLayout="row" fxLayoutAlign="center center">
            <button mat-raised-button type="submit" [disabled]="assignmentForm.invalid" class="blue-button">
                Create
            </button>
            <button (click)="closeDialog()" mat-raised-button type="button">Cancel</button>
        </div>
    </form>
</div>
