/**
 * PriceRemovalConfirmationComponent:
 *
 * This component handles the user interface for confirming a price removal action within a dialog box.
 * It is responsible for:
 * 1. Displaying a form where the user can add a comment about the price removal request.
 * 2. Validating the input comment to ensure it has a minimum number of words (3 words).
 * 3. Submitting the request to the API for processing, passing the necessary data (such as property price IDs and the user’s comment).
 * 4. Closing the dialog with or without submitting data depending on the user's interaction.
 *
 * The component is triggered by an action in the parent component and is expected to receive:
 * - The selected entity type (which could represent a specific property or other data entity) from the parent.
 * - A list of property price IDs that are associated with the removal request.
 *
 * Methods:
 * - `ngOnInit`: Initializes the form and sets up the selected entity type for price removal.
 * - `close`: Closes the dialog without performing any action.
 * - `deletePriceRequest`: Submits the price removal request to the API after validating the comment.
 * - `comment`: Getter for the comment form control to facilitate easier validation and access.
 *
 * Dependencies:
 * - `ApiserviceService`: Used for making API calls to submit the price removal request.
 * - `MatDialogRef`: Provides methods for managing the dialog, such as closing it with a response.
 * - `MAT_DIALOG_DATA`: Provides data passed to the dialog, including the selected entity and price IDs.
 *
 * @selector app-price-removal-confirmation
 * @templateUrl ./price-removal-confirmation.component.html
 * @styleUrls ['./price-removal-confirmation.component.scss']
 */

import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { minWordsValidator } from '../../../../../../src/app/admin/admin-attribute-deletion/validations/minWordsValidator';
import { ApiserviceService } from '../../../../../../src/app/apiservice.service';

@Component({
  selector: 'app-price-removal-confirmation',
  templateUrl: './price-removal-confirmation.component.html',
  styleUrls: ['./price-removal-confirmation.component.scss']
})
export class PriceRemovalConfirmationComponent {
  /** Selected entity type for which price removal is being requested. */
  selectedEntityType: string = "";

  /** Form group to hold the comment input with validation. */
  deleteRequestGroup: FormGroup;

  /** Log IDs to track related logs during the deletion process. */
  logId: string[] = [];

  /**
   * Constructor to initialize the component.
   *
   * @param apiService Service to handle API requests.
   * @param fb FormBuilder to build form controls.
   * @param dialogRef Reference to the dialog window for closing and passing data.
   * @param data Injected data passed to the dialog, including selected entity and price IDs.
   */
  constructor(
    private apiService: ApiserviceService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<PriceRemovalConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  /**
   * ngOnInit lifecycle hook to initialize form controls and set selected entity type.
   *
   * @returns void
   */
  ngOnInit(): void {
    // Set the selected entity type passed from the dialog data
    this.selectedEntityType = this.data.selectedEntity;

    // Initialize the form group with a comment field having a minimum word count validator
    this.deleteRequestGroup = this.fb.group({
      comment: ["", [Validators.required, minWordsValidator(3)]],
    });
  }

  /**
   * Closes the dialog without any action.
   *
   * @returns void
   */
  close(): void {
    this.dialogRef.close();
  }

  /**
   * Sends the price removal request to the API after validating the form.
   * The request includes the selected property price IDs and the user's comment.
   *
   * @returns void
   */
  deletePriceRequest(): void {
    // Prepare the request body with necessary details
    const body = {
      property_price_ids: this.data.property_price_ids,
      user_id: this.apiService.user_id,
      remark: this.deleteRequestGroup.value.comment,
    };

    // Define the API endpoint for price removal
    const url = `transaction-upload/delete`;

    // Call the API service to submit the request
    this.apiService.postEpsData(url, body).subscribe({
      next: (res: any) => {
        console.log(res, "res");
        // Close the dialog and pass the response data upon success
        this.dialogRef.close(res?.data);
      },
      error: (err: any) => {
        // Handle error if any occurs during the API request
        console.error('Error during price removal:', err);
      },
    });
  }

  /**
   * Getter for the 'comment' form control.
   *
   * @returns AbstractControl
   */
  get comment(): AbstractControl {
    return this.deleteRequestGroup.get('comment')!;
  }
}
