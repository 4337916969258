import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-admin-chips',
  templateUrl: './admin-chips.component.html',
  styleUrls: ['./admin-chips.component.scss']
})
export class AdminChipsComponent {
  @Input() chipsLabel:any;
  @Output() chipRemoved = new EventEmitter<number>();

  removeChip(index: number): void {
    if (index >= 0) {
      this.chipsLabel.splice(index, 1); // Remove the chip from the array
      this.chipRemoved.emit(index); // Notify the parent component
    }
  }

}
