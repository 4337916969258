import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { NotificationService } from '../../../../../../src/app/admin/services/notification.service';
import { ApiserviceService } from '../../../../../../src/app/apiservice.service';

@Component({
  selector: 'app-manage-price-filter',
  templateUrl: './manage-price-filter.component.html',
  styleUrls: ['./manage-price-filter.component.scss']
})
export class ManagePriceFilterComponent implements OnInit {
  @Input() columnNameList = [];
  @Output() resetFilter = new EventEmitter<void>();
  @Output() dataRange = new EventEmitter<any>();
  @Output() sendFilteredSectionData = new EventEmitter<any[]>();
  @Output() filterOptionsChanged = new EventEmitter<any>();
  @Input() emittedIndex: number | null = null;

  dynamicForm: FormGroup;
  options: any;
  placeholder: string = 'Enter';
  type: string = '';
  selectedDateRange: [string, string] | null = null;

  // Variables to store added_date, updated_date, and date_range
  addedDate: string | null = null;
  updatedDate: string | null = null;
  dateRange: string | null = null;

  filterValueDataType: { [key: string]: string } = {
    added_by: 'number',
    added_date: 'date',
    area: 'number',
    date: 'date',
    entity_type: 'text',
    government_id: 'text',
    outlier: 'boolean',
    prop_type_estater: 'text',
    prop_type_govt: 'text',
    property_uid: 'text',
    source_name: 'text',
    source_type: 'text',
    transaction_id: 'number',
    transaction_type: 'text',
    transaction_value: 'number',
    unit_uid: 'text',
    updated_by: 'number',
    updated_date: 'date',
    year: 'number',
  };
  transDataList: any;
  transOptions: any;
  selectedYear: number;
  currentYear: number;
  startYear: number;
  enityType: { value: any; label: any; }[];

  constructor(
    private fb: FormBuilder,
    private notify: NotificationService,
    private cdr: ChangeDetectorRef,
    private apiService:ApiserviceService
  ) {
    this.dynamicForm = this.fb.group({
      rows: this.fb.array([this.createRow()])
    });
    this.currentYear = new Date().getFullYear();
    this.startYear = 2009;
  }



  ngOnChanges(changes: SimpleChanges): void {
    if (changes['columnNameList']) {
      if (this.columnNameList && this.columnNameList.length > 0) {
        this.options = this.columnNameList.map((column: string) => ({
          value: column,
          label: column
        }));
      }
    }
    if (this.emittedIndex !== null) {
      this.removeRow(this.emittedIndex);
      this.getFilteredOptions(this.emittedIndex);
    }
  }
  ngOnInit(): void {
    this.getTransType()
  }
  get rows(): FormArray {
    return this.dynamicForm.get('rows') as FormArray;
  }

  createRow(): FormGroup {
    return this.fb.group({
      columnName: [null, Validators.required],
      columnValue: ['', Validators.required]
    });
  }

  addRow(): void {
    // Validate form and check if any date fields are populated
    if (this.dynamicForm.invalid) {
      this.notify.notify(
        'Enter a valid value before adding a new item',
        'warn'
      );
      return;
    }

    // Add a new row
    this.rows.push(this.createRow());
    this.cdr.detectChanges();
  }

  removeRow(index: number): void {
    const rows = this.dynamicForm.get('rows') as FormArray;
    if (rows.length > 1) {
      rows.removeAt(index);
    } else {
      this.resetForm();
    }
  }

  removeRowFromChips(index: number): void {
    console.log("removeRowFromChips called with index:", index);

    const rows = this.dynamicForm.get('rows') as FormArray;

    // Remove the row at the specified index
    if (rows.length > 1) {
      rows.removeAt(index);
    } else {
      this.resetForm(); // Reset the form if it's the last chip
      this.resetFilter.emit(); // Notify parent to reset
    }

    // Emit the updated filter options
    const selectedFilterOptions = this.dynamicForm.value.rows.filter(row => row.columnName && row.columnValue);
    this.filterOptionsChanged.emit(selectedFilterOptions);

    // Handle the case where no filters remain
    if (selectedFilterOptions.length === 0) {
      this.resetFilter.emit(); // Notify parent to reset
    }
  }



  resetForm(): void {
    const rows = this.dynamicForm.get('rows') as FormArray;

    while (rows.length > 1) {
      rows.removeAt(rows.length - 1);
    }

    rows.at(0).reset({
      columnName: null,
      columnValue: ''
    });

    this.resetFilter.emit();
    this.dynamicForm.markAsPristine();
    this.dynamicForm.markAsUntouched();

    this.addedDate = null;
    this.updatedDate = null;
    this.dateRange = null;
  }

  getFilteredOptions(index: number) {
    if (!this.options || !Array.isArray(this.options)) {
      return [];
    }

    const selectedValues = this.rows.controls
      .map((control) => control.get('columnName')?.value)
      .filter((value) => value);

    return this.options.filter(
      (option) =>
        !selectedValues.includes(option.value) ||
        selectedValues[index] === option.value
    );
  }

  onSelectionChange(index: number): void {
    const selectedRow = this.rows.at(index);
    const selectedColumn = selectedRow.get('columnName')?.value;

    // Reset the columnValue when columnName changes
    selectedRow.patchValue({
      columnValue: '' // Clear the input or dropdown selection
    });

    if (selectedColumn) {
      const inputType = this.filterValueDataType[selectedColumn];
      this.type = inputType;

      if (selectedColumn === 'added_date') {
        this.addedDate = null; // Clear any stored added_date value
      } else if (selectedColumn === 'updated_date') {
        this.updatedDate = null; // Clear any stored updated_date value
      }

      if (selectedColumn === 'transaction_type') {
        this.getTransType();
      }

      if (selectedColumn === 'entity_type') {
        this.enityType = this.apiService.entity_type_list
          .filter((item: any) => item.entity_name !== 'property_floor')
          .map((item: any) => ({
            value: item.entity_name === 'property_unit' ? 'unit' : item.entity_name,
            label: item.entity_name === 'property_unit' ? 'unit' : item.entity_name
          }));
      }

      this.placeholder = inputType;
    }
  }


  onDateRangeSelected(dateRange: [string, string], rowIndex: number): void {
    const selectedRow = this.rows.at(rowIndex); // Get the specific row
    const columnName = selectedRow.get('columnName')?.value; // Get the column name for this row

    // Format the date range as "YYYY/MM/DD-YYYY/MM/DD"
    const formattedDateRange = `${dateRange[0]}-${dateRange[1]}`;

    // Assign the formatted date range to the respective column
    if (columnName === 'added_date') {
      this.addedDate = formattedDateRange; // Store the formatted string for added_date
    } else if (columnName === 'updated_date') {
      this.updatedDate = formattedDateRange; // Store the formatted string for updated_date
    } else if (columnName === 'date') {
      this.dateRange = formattedDateRange; // Store the formatted string for date
    }

    // Update the form's columnValue with the formatted date range
    selectedRow.patchValue({
      columnValue: formattedDateRange
    });
    console.log(`Date Range Selected for ${columnName}:`, formattedDateRange);
  }


  onSubmit(): void {
    if (!this.dynamicForm.valid && !this.dateRange) {
      this.notify.notify('Select one filter at least for apply filter', 'warn');
      return;
    } else {
      const selectedFilterOptions = this.dynamicForm.value.rows;
      this.sendFilteredSectionData.emit(selectedFilterOptions);
      this.dataRange.emit(this.dateRange);
    }
  }

  isDateColumn(row: any): boolean {
    const columnName = row.get('columnName').value;
    return columnName === 'added_date' || columnName === 'updated_date' || columnName === 'transaction_date';
  }

  getTransType(): void {
    const url = `master-values-by-master?master_name=transaction_upload_option&entity=property`;
    this.apiService.getEmsData(url).subscribe({
      next: (res: any) => {
        this.transDataList = res.data;
        this.transOptions = this.transDataList.map((item: any) => ({
          value: item.master_value_name,
          label: item.master_value_name
        }));
      },
      error: (err) => console.error('Error fetching Trans Type options:', err),
    });
  }
  onTransSelectionChange(index: number, event: any): void {
    const selectedRow = this.rows.at(index);
    if (!selectedRow) {
      console.error('No row found at index:', index);
      return;
    }
    // Ensure columnValue is patched correctly
    selectedRow.patchValue({
      columnValue: event, // Fallback to an empty string if value is undefined
    });
    this.dynamicForm.updateValueAndValidity();
  }
  onEntitySelectionChange(index: number, event: any): void {
    const selectedRow = this.rows.at(index);
    if (!selectedRow) {
      console.error('No row found at index:', index);
      return;
    }
    // Ensure columnValue is patched correctly
    selectedRow.patchValue({
      columnValue: event, // Fallback to an empty string if value is undefined
    });
    this.dynamicForm.updateValueAndValidity();
  }
  // Handles the chosen year for the date picker
  handleYearSelected(year: number) {
    console.log("Selected Year:", year);
    this.selectedYear = year;
  }
}
