<div class="select-wrapper">
  <mat-form-field appearance="outline" floatLabel="always">
    <mat-label>{{ label }}</mat-label>
    <mat-select
      [value]="value"
      [multiple]="multiple"
      (selectionChange)="onSelectionChange($event)"
    >
      <mat-option *ngFor="let option of options" [value]="option.value">
        {{ option.label | removeUnderscore }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
