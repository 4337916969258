<mat-toolbar *ngIf="!data.edit" class="matpopup-header">
  <div>Create Attribute</div>
</mat-toolbar>
<mat-toolbar *ngIf="data.edit" class="matpopup-header">
  <div>Edit Attribute</div>
</mat-toolbar>
<form [formGroup]="attributeForm" (ngSubmit)="saveAttribute(attributeForm)">
  <mat-dialog-content>
    <!-- <p>Validation status: {{ attributeForm.status }}</p>-->
    <div class="form-container">
      <div class="form-input-container" fxLayout="row" fxLayoutAlign="space-between center">
        <label fxFlex="50%">Attribute Name</label>
        <mat-form-field floatLabel="never" fxFlex="50%">
          <input type="text" [errorStateMatcher]="matcher" matInput #attribute_name formControlName="attribute_name"
            required minlength="3" maxlength="50" />
          <mat-hint align="end">
            {{ attribute_name.value.length }}/50
          </mat-hint>
          <mat-error *ngIf="attributeName.hasError('pattern')">Special characters are not allowed</mat-error>
          <mat-error *ngIf="(attributeForm.controls['attribute_name'].hasError('minlength') || attributeForm.controls['attribute_name'].hasError('maxlength')) && !attributeName.hasError('pattern')">Attribute name should be 3-64 character</mat-error>
          <mat-error *ngIf="attributeForm.controls['attribute_name'].touched && !attributeForm.controls['attribute_name'].hasError('minlength') && !attributeName.hasError('pattern')">Name is required</mat-error>
        </mat-form-field>
      </div>
      <div class="form-input-container" *ngIf="data.pObj.is_property_dependent && !data.edit && !showParent"
        fxLayout="row" fxLayoutAlign="space-between center">
        <label fxFlex="50%">Property Type</label>
        <mat-form-field floatLabel="never" fxFlex="50%">
          <mat-select formControlName="property_type" [errorStateMatcher]="matcher" multiple
            (selectionChange)="countProperty($event.value)">
            <mat-option *ngFor="let property of property_type" [value]="property.property_type_id">
              {{ property.property_type_label[api.language] }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-input-container" fxLayout="row" fxLayoutAlign="space-between center">
        <label fxFlex="50%">Data Type</label>
        <mat-form-field floatLabel="never" fxFlex="50%">
          <mat-select [errorStateMatcher]="matcher" formControlName="data_type"
            (selectionChange)="dataTypeChange($event.value)">
            <mat-option *ngFor="let data of data_type" [value]="data">
              {{ data }}
            </mat-option>
          </mat-select>
          <mat-error>Select valid datatype</mat-error>
        </mat-form-field>
      </div>

      <div class="my-2 form-input-container" fxLayout="row" fxLayoutAlign="space-between center">
        <label fxFlex="50%">Is Unique</label>
        <mat-checkbox formControlName="is_unique" fxFlex="50%"></mat-checkbox>
      </div>

      <div class="my-2 form-input-container" fxLayout="row" fxLayoutAlign="space-between center">
        <label fxFlex="50%">Is Frequently Searched</label>
        <mat-checkbox formControlName="is_frequently_search" fxFlex="50%"></mat-checkbox>
      </div>
      <div class="my-2 form-input-container" fxLayout="row" fxLayoutAlign="space-between center">
        <label fxFlex="50%">Is Language Dependant</label>
        <mat-checkbox formControlName="is_language_dependent" fxFlex="50%"></mat-checkbox>
      </div>
      <div class="my-2 form-input-container" fxLayout="row" fxLayoutAlign="space-between center">
        <label fxFlex="50%">Is System Attribute</label>
        <mat-checkbox formControlName="is_system_attribute" fxFlex="50%"></mat-checkbox>
      </div>

      <div class="form-input-container" *ngIf="showDefaultValue" fxLayout="row" fxLayoutAlign="space-between center">
        <label fxFlex="50%">Default Value</label>
        <mat-form-field floatLabel="never" fxFlex="50%">
          <input formControlName="default_value" [errorStateMatcher]="matcher" type="{{ defaultValueType }}" matInput />
        </mat-form-field>
      </div>

      <div class="form-input-container" *ngIf="showFieldLength" fxLayout="row" fxLayoutAlign="space-between center">
        <label fxFlex="50%">Field Length</label>
        <mat-form-field floatLabel="never" fxFlex="50%">
          <input formControlName="field_length" type="number" matInput />
        </mat-form-field>
      </div>
      <div class="form-input-container" *ngIf="showMeasure" fxLayout="row" fxLayoutAlign="space-between center">
        <label fxFlex="50%">Measure Type </label>
        <mat-form-field floatLabel="never" fxFlex="50%">
          <mat-select (selectionChange)="changeMeasureUnit($event.value)" formControlName="measure_type">
           <ng-container *ngFor="let measure of measure_type">
            <mat-option  [value]="measure">
              {{ measure }}
            </mat-option>
           </ng-container>

          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-input-container" *ngIf="showMeasure && isMeasureUnitShow" fxLayout="row"
        fxLayoutAlign="space-between center">
        <label fxFlex="50%">Measure Unit <span class="error-message">*</span></label>
        <mat-form-field floatLabel="never" fxFlex="50%">
          <mat-select formControlName="measure_unit">
            <mat-option *ngFor="let measure of measure_unit" [value]="measure">
              {{ measure }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- <div *ngIf="showDependent" class="form-input-container" fxLayout="row" fxLayoutAlign="space-between center">
            <label fxFlex="50%">Dependant Attributes</label>
            <mat-form-field floatLabel="never" fxFlex="50%">
                <mat-select formControlName="dependent_attribute" multiple>
                    <mat-option *ngFor="let attr of attributes" [value]="attr.attribute_id">
                        {{attr.attribute_name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>  -->

      <div *ngIf="showDependent" class="form-input-container" fxLayout="row" fxLayoutAlign="space-between center">
        <label class="mutual-label" fxFlex="50%">Mutually Exclusive Attributes</label>
        <mat-form-field floatLabel="never" fxFlex="50%">
          <mat-select formControlName="mutual_exclusive_attribute">
            <div class="search_bar">
              <input mat-input [(ngModel)]="searchText" [ngModelOptions]="{ standalone: true }"
                placeholder="Type here to search..." (keydown)="$event.stopPropagation()" />
            </div>
            <mat-option style="color: #848484" [value]="''">Select attribute</mat-option>
            <mat-option *ngFor="
                let attr of attributes
                  | searchfilter : 'attribute_name' : searchText
              " [value]="attr.attribute_id">
              {{ attr.attribute_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-input-container" *ngIf="showMaster" fxLayout="row" fxLayoutAlign="space-between center">
        <label fxFlex="50%">Associated Master</label>
        <mat-form-field floatLabel="never" fxFlex="50%">
          <mat-select formControlName="associated_master" (selectionChange)="validateMaster($event.value)">
            <ng-container *ngFor="let mst of masters">
              <mat-option *ngIf="
                  (!mst.parent_master_id && mst.parent_master_id == null) ||
                  showParent
                " [value]="mst.master_id">
                {{
                mst.master_label[api.language] == undefined
                ? mst.master_name
                : mst.master_label[api.language]
                }}
              </mat-option>
            </ng-container>
          </mat-select>
          <span *ngIf="master_valid" class="mat-err">Select master for attribute</span>
        </mat-form-field>
      </div>
      <div *ngIf="showDependent && showParent" class="form-input-container" fxLayout="row"
        fxLayoutAlign="space-between center">
        <label fxFlex="50%">Parent Attribute</label>
        <mat-form-field floatLabel="never" fxFlex="50%">
          <mat-select formControlName="parent_attribute" (selectionChange)="updateMasterList($event.value)">
            <div class="search_bar">
              <input mat-input [(ngModel)]="searchText" [ngModelOptions]="{ standalone: true }"
                placeholder="Type here to search..." (keydown)="$event.stopPropagation()" />
            </div>
            <mat-option style="color: #848484" [value]="''">Select attribute</mat-option>
            <mat-option *ngFor="
                let attr of attributes
                  | searchfilter : 'attribute_name' : searchText
              " [value]="attr.attribute_id">
              {{ attr.attribute_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!--<div class="form-input-container" fxLayout="row" fxLayoutAlign="space-between center">
            <label fxFlex="50%">Status</label>
                <mat-slide-toggle
                class="example-margin" [checked]="status" [(ngModel)]="status" [ngModelOptions]="{standalone: true}">
            </mat-slide-toggle>
        </div>-->
        <label>Description</label>
        <mat-form-field floatLabel="never" style="width: 100%;">
          <textarea type="text" style="max-height: 100px; min-height: 70px;" maxlength="250" matInput name="value" formControlName="description" #description></textarea>
              <mat-hint align="end">{{description.value.length}}/250</mat-hint>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" class="dialog-actions">
    <button mat-raised-button type="submit" [disabled]="attributeForm.invalid" class="blue-button">
      Submit
    </button>
    <button mat-raised-button type="button" (click)="closeDialog()">
      Cancel
    </button>
  </mat-dialog-actions>
</form>
