<mat-form-field appearance="outline" class="select-wrapper" floatLabel="always">
  <mat-label>Select Date Range</mat-label>
  <mat-date-range-input [formGroup]="dateRangeForm" [rangePicker]="picker" (click)="openPicker(picker)">
    <!-- Set readonly to disable manual input, and handle click to open the calendar -->
    <input matStartDate formControlName="start" [placeholder]="placeholderStart" readonly >
    <input matEndDate formControlName="end" [placeholder]="placeholderEnd" readonly >
  </mat-date-range-input>
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
