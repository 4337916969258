import { Component, Inject, ViewChild } from "@angular/core";
import { MatErrorStateMatcher } from "../../../admin-error-state-macher";
import { FormBuilder, Validators, FormArray } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiserviceService } from "../../../../apiservice.service";
import { CommonfunctionService } from "../../../../services/commonfunction.service";
import { NotificationService } from "../../../services/notification.service";

/**
*
* <strong>List of API using</strong>
* <ol>
* <li>estapi_entity_masters_master_id_values_master_value_id_patch</li>
* <li>estapi_entity_masters_master_id_values_patch</li>
* </ol>
*
*/



@Component({
  selector: 'master-subvalue-dialog',
  templateUrl: 'admin-createsubvalue.component.html',
  styleUrls: ['admin-create-submaster-value.scss']
})
export class CreateSubMasterValueDialog {
  matcher = new MatErrorStateMatcher();
  subMasterValueForm: any;
  language_valueset: Array<{}> = [];
  languages: any;
  status: boolean = true;
  defaultValues: any;
  allSubmasterVal: any = [];
  checkedSubmasterValues: any = [];
  public add_sub_master_value = {
    "added_by": this.api.user_id,
    "master_id": this.data.pObj.selected_sub_master.master_id,
    "master_value_label": {},
    "master_value_name": "",
    "parent_master_value_id": this.data.pObj.selected_master_value.id,
    "property_type_ids": null,
    "parent_master_value_ids": null,
    "status": 1,
    "sort_order": 0
  };
  duplicateLang: any;
  constructor(
    public dialogRef: MatDialogRef<CreateSubMasterValueDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public api: ApiserviceService,
    private _fb: FormBuilder,
    private notify: NotificationService,
    private fnc: CommonfunctionService) {
    this.languages = api.language_list;
    this.allSubmasterVal = this.data.pObj.allSubmasterVal;
    if (this.data.pObj.selected_master_value.property_type_id) {
      this.add_sub_master_value.property_type_ids = [this.data.pObj.selected_master_value.property_type_id];
    }
    /* #Form - Intial Form Definition */
    this.subMasterValueForm = this._fb.group({
      name: [{ value: '', disabled: this.data.edit }],
      language: this._fb.array([])
    });
    // if edit
    if (this.data.edit) {
      //console.log(this.data.sub_master);
      let url = this.data.pObj.selected_entity_type + "/masters/" + this.data.pObj.selected_sub_master.master_id + "/values/" + this.data.sub_master.id;
      let labels: any = [];
      let index = 0;
      this.status = this.data.sub_master.status;
      for (let prop in this.data.sub_master.labels) {
        labels[index] = { language: +prop, value: this.data.sub_master.labels[prop] };  // +prop to convert into number
        this.addRow();
        index++
      }
      this.subMasterValueForm.patchValue({ language: labels });
      setTimeout(() => {    // not able to patch without timeout
        this.subMasterValueForm.patchValue({ name: this.data.sub_master.name });
      }, 100);
    } else {
      this.addRow();
      this.subMasterValueForm.patchValue({ 'language': [{ language: this.languages[0].language_id, value: '' }] });
    }
    if (this.data.pObj.sub_master_value_list) {
      this.data.pObj.sub_master_value_list.forEach(v => {
        this.checkedSubmasterValues.push(v.id);
      });
    }
    this.defaultValues = this.checkedSubmasterValues;
  }
  // selected duplicate language
  selectedLang(languageList){
    languageList.forEach(lang => {
      this.duplicateLang = languageList.filter((val) => val.language === lang.language); // filtering duplicate languages list
    });
}
  addSubmasterValue(val, e) {
    let langList = this.subMasterValueForm.value.language; // selected language list
    this.selectedLang(langList)
    let isEnglishSelected = langList.find(ele => ele.language == 1); // finding english language selected or not
    if(this.duplicateLang.length > 1){
      this.notify.notify('You have selected same language multiple time', 'warn'); // if selected same language multiple time
      return
     }
    if(!isEnglishSelected){
      this.notify.notify('English language is required', 'warn'); // if english language not selected
      return
    }
    let value = val.value;
    let re = /\ /gi;
    value.language.forEach((v, k) => {
      this.add_sub_master_value.master_value_label[v.language] = v.value;
    });
    if (this.status) {
      this.add_sub_master_value.status = 1;
    } else {
      this.add_sub_master_value.status = 0;
    }
    this.add_sub_master_value.parent_master_value_id = this.data.pObj.selected_master_value.id;
    this.add_sub_master_value['parent_master_value_ids'] = this.data.pObj.selected_master_value.id;
    if (this.checkedSubmasterValues != this.defaultValues) {
      this.updateParentIds(this.checkedSubmasterValues);
    }
    if (this.data.edit) {
      this.add_sub_master_value['updated_by'] = this.api.user_id;
      this.add_sub_master_value.master_value_name = this.data.sub_master.name;
      this.api.patchEmsData(this.data.pObj.selected_entity_type + '/masters/' + this.data.pObj.selected_sub_master.master_id + '/values/' + this.data.sub_master.id, this.add_sub_master_value)
        .subscribe(
          {next :(data:any) => {
            this.notify.notify('Sub Master Value Updated', 'success');
            this.data.pObj.getSubMasterValue(this.data.pObj.selected_sub_master, 'master_value_name', 500, 0);
          },
          error : (err) => this.notify.notify(this.fnc.getErrorMessage(err), 'error'),
           complete : () => this.closeDialog(e)
          }
        );
    } else if (this.add_sub_master_value.master_value_label[this.api.language] && value.name) {
      this.add_sub_master_value.master_value_name = value.name.replace(re, '_');

      this.api.postEmsData(this.data.pObj.selected_entity_type + '/masters/' + this.data.pObj.selected_sub_master.master_id + '/values', this.add_sub_master_value)
        .subscribe(
        { next : (data:any) => {
            this.notify.notify('Sub Master Value Created', 'success');
            this.data.pObj.getSubMasterValue(this.data.pObj.selected_sub_master, 'master_value_name', 500, 0);
          },
         error: (err) => this.notify.notify(this.fnc.getErrorMessage(err), 'error'),
         complete : () => this.closeDialog(e)
        }
        );
    }
  }
  updateParentIds(subMasterValues) {
    let body = [], obj, item, flag = false;
    let url = this.data.pObj.selected_entity_type + '/masters/' + this.data.pObj.selected_sub_master.master_id + '/values';
    subMasterValues.forEach(v => {
      obj = this.fnc.getArrayValue('id', v, this.allSubmasterVal);
      if (!obj.parent_master_value_ids) {
        item = {
          "master_value_id": v,
          "parent_master_value_ids": this.data.pObj.selected_master_value.id + '',
          "updated_by": this.api.user_id
        }
        flag = true;
        body.push(item)
      } else {
        if (!obj.parent_master_value_ids.includes(this.data.pObj.selected_master_value.id)) {
          item = {
            "master_value_id": v,
            "parent_master_value_ids": obj.parent_master_value_ids + ',' + this.data.pObj.selected_master_value.id,
            "updated_by": this.api.user_id
          }
          flag = true;
          body.push(item)
        }
      }

    });
    if (flag) {
      this.api.patchEmsData(url, body).subscribe(
       {next: (data:any) => {
        this.data.pObj.getSubMasterValue(this.data.pObj.selected_sub_master, 'master_value_name', 500, 0);
        this.notify.notify('Sub Master Value linked to selected Master', 'warn');
        this.dialogRef.close();
      }, error:(err) => {
        //  console.log(err);
      }}
      )
    }
  }

  getSelectedOption(e) {
    e.value.forEach(item => {
      if (!this.checkedSubmasterValues.includes(item)) this.checkedSubmasterValues.push(item);
    });
  }
  initLanguageFields() {
    return this._fb.group({
      language: ['', Validators.required],
      value: ['', Validators.required]
    })
  }

  addRow() {
    if (this.language_valueset?.length == this.languages?.length) {
      this.notify.notify("No more Languages Available to Add",'warn',4000);
      return;
    }
    this.language_valueset.push({ language: '', value: '' });
    /* #Form */
    let dd = <FormArray>this.subMasterValueForm.controls.language;
    dd.push(this.initLanguageFields());
    /* End - #Form */
  }

  removeRow(key) {
    if (this.language_valueset.length > 1) {
      this.language_valueset.splice(key, 1);
      let dd = <FormArray>this.subMasterValueForm.controls.language;
      dd.removeAt(key);
    }
  }

  closeDialog(e): void {
    e.preventDefault();
    this.dialogRef.close();
  }

}
