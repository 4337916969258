<div class="dialog">
  <div class="profile">
    <div class="section-1"  *ngIf="!change">
      <div class="picture">
        <div class="loading" *ngIf="loader">
          <div [ngClass]="{loader: loader}"></div>
        </div>
        <img id="pPic" src="{{profile_image}}" alt="Picture">
      </div>
      <div class="gallery">
        <button (click)="openExplorer('image')" matTooltip="Edit" class="cent edit-icon">
          <img src="assets/icon/edit-outline.svg" alt="Edit Icon">
          <input id="image_input"  #imageInput (change)="uploadExplorImage($event)" class="hidden d-none" accept="image/*" type="file" />
        </button>
      </div>
    </div>
    <div id="backDrop">
      <form [formGroup]="profileForm" (ngSubmit)="saveUserdetail(profileForm)">
        <ngx-loading [show]="loader1" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.6)'}"></ngx-loading>
        <div class="section-2" *ngIf="!change">
          <div class="row">
            <div id="name" class="col-md-6">
              <span class="spclr">First Name</span>
              <div id="inputs">
                <input class="form-control input-sm" type="text" formControlName="fname" placeholder="Firstname">
                <mat-error *ngIf="profileForm.controls['fname'].hasError('required')"> First name is Required! </mat-error>
                <mat-error *ngIf="profileForm.controls['fname'].hasError('pattern')"> First name is invalid! </mat-error>
                <mat-error *ngIf="profileForm.controls['fname'].hasError('maxlength')"> First name no more than 45 characters! </mat-error>
                <mat-error *ngIf="profileForm.controls['fname'].hasError('minlength')"> First name minimum 2 characters</mat-error>

              </div>
            </div>
            <div id="name" class="col-md-6">
              <span class="spclr">Last Name</span>
              <div id="inputs">
                <input class="form-control input-sm" type="text" formControlName="lname" placeholder="Lastname">
                <mat-error *ngIf="profileForm.controls['lname'].hasError('required')"> Last name is Required! </mat-error>
                <mat-error *ngIf="profileForm.controls['lname'].hasError('pattern')"> Last name is invalid! </mat-error>
                <mat-error *ngIf="profileForm.controls['lname'].hasError('maxlength')"> Last name no more than 45 characters! </mat-error>
                <mat-error *ngIf="profileForm.controls['lname'].hasError('minlength')"> Last name minimum 2 characters</mat-error>

              </div>
            </div>
          </div>
          <div id="email">
            <span class="spclr">E-Mail</span>
            <input class="form-control input-sm" type="email" formControlName="email" placeholder="e-mail">
          </div>
          <div id="contact">
            <span class="spclr">Contact</span>
            <!-- <input class="form-control input-sm" type="number" formControlName="contact" placeholder="+99 999 999 9999"> -->
            <div class="form-control" style="display: table-footer-group;">
              <ngx-intl-tel-input [onlyCountries]="preferredCountries"
                [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                [searchCountryField]="[SearchCountryField.All]" [selectFirstCountry]="true" [maxLength]="15"
                [phoneValidation]="true" [separateDialCode]="true" [numberFormat]="PhoneNumberFormat.National"
                formControlName="contact">
              </ngx-intl-tel-input>
              </div>
          </div>
          <div class="row">
            <div id="city" class="col-md-6">
              <span class="spclr">City</span>
              <mat-select class="form-control" formControlName="city">
                <mat-option *ngFor="let city of city_list" class="ddList" value="{{city.place_name}}">{{city.place_name}}
                </mat-option>
              </mat-select>
            </div>
            <div id="language" class="col-md-6">
              <span class="spclr">Language</span>
              <mat-select class="form-control" formControlName="language">
                <mat-option *ngFor="let lang of language_list" class="ddList" value="{{lang.language_code}}">{{lang.name}}
                </mat-option>
              </mat-select>
            </div>
          </div>
          <div class="right-btn">
            <div class="free-space"></div>
            <div class="cent">
              <button [disabled]="profileForm.invalid || loader" mat-button class="bluebutton1" type="submit">
                <span class="fnt-up" *ngIf="!loader && !loader1">
                  Save
                </span>
                <span class="fnt-up" *ngIf="loader || loader1">
                  Saving...
                </span>
              </button>
              <button mat-button class="orange1" (click)="changePass()">
                <span class="fnt-up">
                  Change Password
                </span>
              </button>
            </div>

          </div>
          <div>
            <input type="hidden" formControlName="image" />
          </div>
        </div>
      </form>
      <form [formGroup]="changePasswordForm" autocomplete="off">
        <div class="section-3" *ngIf="change">
          <div class="mb-2"><h4>Change password</h4></div>
          <div id="pass">
            <span class="spclr mb-1">Old Password</span>
            <input  class="form-control input-sm" autocomplete="new-password"  type="password" formControlName="oldPassword"
              placeholder="Old Password">
          </div>
         <mat-error class="help-block" *ngIf="changePasswordForm.controls['oldPassword'].hasError('required') && changePasswordForm.get('oldPassword').touched">
          Current password is required.
        </mat-error>
          <div id="pass">
            <span class="spclr mb-1">New Password</span>
            <input class="form-control input-sm" type="password"  formControlName="password"
             placeholder="New Password">
          </div>
          <div>
             <mat-error  *ngIf="changePasswordForm.controls['password'].hasError('required') && changePasswordForm.get('password').touched">
              New password is required.
         </mat-error>
          </div>
          <div>
            <mat-error *ngIf="changePasswordForm.controls['password'].hasError('minlength'); else hasNumber">
             New password may not be less than 8 characters.
           </mat-error>
          <ng-template #hasNumber>
            <mat-error *ngIf="changePasswordForm.controls['password'].hasError('hasNumber'); else hasCapitalCase">
              New password should contain at least 1 numeric character.
            </mat-error>
          </ng-template>
        <ng-template #hasCapitalCase>
            <mat-error *ngIf="changePasswordForm.controls['password'].hasError('hasCapitalCase'); else hasSmallCase">
              New password should contain at least 1 uppercase character.
            </mat-error>
          </ng-template>
          <ng-template #hasSmallCase>
            <mat-error *ngIf="changePasswordForm.controls['password'].hasError('hasSmallCase'); else hasSpecialCharacters">
              New password should contain at least 1 lowercase character.
            </mat-error>
          </ng-template>
          <ng-template #hasSpecialCharacters>
            <mat-error *ngIf="changePasswordForm.controls['password'].hasError('hasSpecialCharacters')">
              New password should contain at least 1 special character.
            </mat-error>
              <mat-error *ngIf="changePasswordForm.controls['password'].hasError('maxlength');">
               New password may not be longer than 20 characters.
           </mat-error>
          </ng-template>
          </div>
          <div id="pass">
            <span class="spclr mb-1">Confirm Password</span>
            <input class="form-control input-sm" type="password"  formControlName="confirmPassword"
              placeholder="Confirm Password">
          </div>

          <div class="error-wrapper">
            <mat-error>
              <span *ngIf="changePasswordForm.controls['confirmPassword'].hasError('required') && changePasswordForm.get('confirmPassword').touched;"> Confirm password is required.</span>
            </mat-error>
            <!-- <ng-template #hasPasswordMatch> -->
            <mat-error><span *ngIf="changePasswordForm.value.confirmPassword.length > 0 && changePasswordForm.controls['confirmPassword'].hasError('NoPassswordMatch')">   Password do not match!</span></mat-error>
          <!-- </ng-template> -->
          </div>
          <div class="right-btn mt-2">
            <div class="free-space"></div>
            <button mat-button type="button" class="bluebutton1" [disabled]="changePasswordForm.invalid" (click)="changePassword()">
              <span class="fnt-up" *ngIf="!loader && !loader1">
                Update
              </span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
