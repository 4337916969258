<!-- Overlay -->
<div class="overlay" [class.open]="isOpen"></div>

<!-- Side Panel -->
<div class="side-panel" [class.open]="isOpen">
  <div class="side-panel-header">
    <h3>{{ title }}</h3>
    <button class="close-button" (click)="onClose()">&#x2715;</button>
  </div>
  <div class="side-panel-content">
    <ng-content></ng-content>
  </div>
</div>
