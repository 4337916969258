export const STATUS_MAP = {
  "-1": { label: "Invalid", color: "#FF4A4A" },
  "1": { label: "Valid", color: "#81A2FF" },
  "2": { label: "Accepted", color: "#0FEB03" },
  "-2": { label: "Accept Invalid", color: "#E3DB05" },
  "0": { label: "Uploaded", color: "#AAAAAA" },
  "-3": { label: "Rejected", color: "#FF4A4A" },
  "4": { label: "Accept Progress", color: "#FF8A3E" },
  "3": { label: "Validating", color: "#ACA710" },
};
