<!-- #1 For Web view-->
<div *ngIf="ready_load">
  <div *ngIf="!is_master_ui" fxHide="true" fxShow.gt-sm="true" fxLayout="row" class="w-100 crumb-c"
    fxLayoutAlign="space-between center">
    <div fxLayout="row" class="w-100 bleft" fxLayoutAlign="space-between center">
      <div fxLayout="row" class="w-100" fxLayoutAlign="start center">
        <admin-breadcrumb></admin-breadcrumb>
        <button (click)="changeView()" class="switch-list">
          <span>Switch the list view</span>
        </button>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center">
        <div fxLayout="row" fxShow="false" fxShow.gt-xs="true" fxLayoutAlign="start center" fxLayoutGap="10px"
          class="search-bar">
          <mat-select [(ngModel)]="selected_entity_type" (selectionChange)="searchMasterValue(false)"
            class="form-select-l">
            <mat-option *ngFor="let ent of api.entity_type_list" [value]="ent.entity_name">{{ent.entity_name}}
            </mat-option>
          </mat-select>
          <mat-select *ngIf="is_property_dependent" [errorStateMatcher]="matcher" [(ngModel)]="selected_property_type"
            (selectionChange)="searchMasterValue(false)" [disabled]="!is_property_dependent" class="form-select-l">
            <div class="search_bar"> <input mat-input [(ngModel)]="searchText" placeholder="Type here to search name..."
                (keydown)="$event.stopPropagation()" /></div>
            <mat-option
              *ngFor="let prp of api.property_type_list | searchfilter:'property_type_name':searchText:api.property_type_list"
              [value]="prp.property_type_id">{{prp.property_type_label[api.language] == undefined ?
              prp.property_type_name : prp.property_type_label[api.language]}}
            </mat-option>
          </mat-select>
          <input [formControl]="searchField" [errorStateMatcher]="matcher" class="form-input-l search-inputbox"
            placeholder="Type to search..." type="text">
          <!-- <button mat-icon-button>
            <mat-icon>search</mat-icon>
          </button> -->
        </div>
      </div>
      <!-- <search-bar [search]="searchFn"></search-bar> -->
    </div>
    <div fxLayout="row" class="cleft" fxLayoutAlign="end center">
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" class="button-b">
        <button *ngIf="mastergroups_access.POST" mat-button class="orange-button"
          (click)="openDialog('mastergroup',{'id':1,'edit':false}, $event)">Create
          Master Group</button>
        <button *ngIf="mastervalues_access.POST" mat-button class="grey-button"
          (click)="openDialog('mastervalue',{'groups':master_group_list,'edit':false}, $event)">Create
          Master Value</button>
      </div>
    </div>
  </div>
</div>
<!-- loader start -->
<div *ngIf="!ready_load">
  <ngx-loading [show]="!ready_load"></ngx-loading>
</div>
<!-- loader end -->
<!-- end of #1 For Web view-->
<app-masters-list-view *ngIf="is_master_ui" (changeUI)="changeView()"></app-masters-list-view>
<!-- #3 For web view -->
<div class="container-fluid" fxHide="true" fxShow.gt-sm="true" fxLayout="row" *ngIf="!is_master_ui">
  <div class="l-cont" [ngStyle]="{'height':height+'px'}" style="position: sticky; top: 0;">
    <h4 class="l-mg">Master Groups</h4>

    <!-- #4 Sortable Master Groups set -->
    <div class="newscroll" [ngStyle]="{'height':inHeight+'px'}">

      <div *ngIf="mastergroups_access.GET" class="list-container">
        <div>
          <!-- <div  cdkDropList  (cdkDropListDropped)="dropGroup($event)"  [cdkDropListDisabled]="dragDisabled"> -->
          <div class="cst rowse" *ngFor="let item of master_group_list;let k = index;" fxLayout="column"
            fxLayoutAlign="start left">
            <!-- <div class="data-placeholder" *cdkDragPlaceholder></div> -->
            <button class="group-b dragx" [ngClass]="{active: isActive(item.id)}" (click)="select(k, item.id)"
              (mouseenter)="showsmallpop(item.id)">
              <!-- <mat-icon class="draghandle" matTooltip="Drag me" svgIcon="move-icon">drag_handle</mat-icon> -->
              <!-- <img width="18" matTooltip="Drag me" (mousedown)="dragDisabled = false;" style="cursor: move; margin: 0 10px 0 0;"
                                                            src="assets/icon/drag.svg" alt=""> -->
              <span class="w-100 title">{{item.title}}</span>
              <mat-icon class="add-b" [ngClass]="{open : isActive(item.id)}"
                *ngIf="item.childrens && item.childrens.length" svgIcon="down-arrow-icon"></mat-icon>
            </button>
            <master-contextmenu [item]="item" [mastergroups_access]="mastergroups_access"
              [masters_access]="masters_access" [openDialog]="dialogFn" class="tooltip-nearmaster"
              [ngClass]="{'showtool' : popwindow===item.id && !submasterpop && dragDisabled}"></master-contextmenu>

            <!-- #5 Submenu Master group -->
            <div class="cs" fxLayout="row" fxLayoutAlign="start start">
              <mat-nav-list class="masterslist w-100" [sortablejs]="item.childrens"
                [sortablejsOptions]="submastergroupoptions" [ngClass]="{active: isActive(item.id)}"
                *ngIf="item.childrens && item.childrens.length">
                <ng-container *ngFor="let childitem of item.childrens;let ks = index">
                  <mat-list-item class="item subdraghandle "
                    (mouseenter)="showsmallsubpop($event, 'tooltip-subnearmaster',ks,false)"
                    (click)="onGroupChange(); searchMasterValue(childitem)">
                    <!-- <mat-icon class="draghandle" matTooltip="Drag me" svgIcon="move-icon"></mat-icon> -->
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 490.677 490.677" style="enable-background:new 0 0 490.677 490.677;" xml:space="preserve" width="18px" height="18px">
                                            <g><g><path fill="#424c73" d="M487.557,237.789l-64-64c-3.051-3.051-7.659-3.947-11.627-2.304c-3.989,1.643-6.592,5.547-6.592,9.856v32h-128v-128h32    c4.309,0,8.213-2.603,9.856-6.592c1.643-3.989,0.725-8.576-2.304-11.627l-64-64c-4.16-4.16-10.923-4.16-15.083,0l-64,64    c-3.051,3.072-3.968,7.637-2.325,11.627c1.643,3.989,5.547,6.592,9.856,6.592h32v128h-128v-32c0-4.309-2.603-8.213-6.592-9.856    c-3.925-1.664-8.555-0.747-11.627,2.304l-64,64c-4.16,4.16-4.16,10.923,0,15.083l64,64c3.072,3.072,7.68,4.011,11.627,2.304    c3.989-1.621,6.592-5.525,6.592-9.835v-32h128v128h-32c-4.309,0-8.213,2.603-9.856,6.592c-1.643,3.989-0.725,8.576,2.304,11.627    l64,64c2.091,2.069,4.821,3.115,7.552,3.115s5.461-1.045,7.552-3.115l64-64c3.051-3.051,3.968-7.637,2.304-11.627    c-1.664-3.989-5.547-6.592-9.856-6.592h-32v-128h128v32c0,4.309,2.603,8.213,6.592,9.856c3.947,1.685,8.576,0.747,11.627-2.304    l64-64C491.717,248.712,491.717,241.971,487.557,237.789z" />
                                            </g></g>
                                        </svg> -->
                    <div class="txt cp">{{childitem.title}}</div>
                    <submaster-contextmenu [masters_access]="masters_access" [master]="childitem"
                      [openDialog]="dialogFn" class="tooltip-subnearmaster"
                      [ngClass]="{'showsubtool' : popsubwindow===ks}">
                    </submaster-contextmenu>
                  </mat-list-item>
                </ng-container>
              </mat-nav-list>
            </div>
            <!-- #5 End of submaster group -->
          </div>
        </div>
      </div>
    </div>
    <!-- End of #4 -->
  </div>

  <div fxFlex="calc3cols" class="col3 ">
    <div class="r-cont position-relative">
      <div class="r-mv h50p" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
          <h4 class="cp" style="font-size: 16px;" *ngIf="selected_master">{{selected_master.title}} Values</h4>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <button *ngIf="showCopy" mat-mini-fab class="fab_copy" (click)="pastemastervalDialog()"
            matTooltip="Copy master value">
            <mat-icon svgIcon="copy"></mat-icon>
          </button>

          <mat-select [(ngModel)]="active_value_status" (selectionChange)="getActiveMasterValue()"
            class="form-select-l">
            <mat-option [value]="'all'">All</mat-option>
            <mat-option [value]="'enable'">Enable</mat-option>
            <mat-option [value]="'disable'">Disable</mat-option>
          </mat-select>
          <!-- select order -->
          <mat-select [(ngModel)]="selected_sorting_type" (selectionChange)="sortBy()" class="form-select-l">
            <mat-option *ngFor="let data of sort_type" [value]="data.value">{{data.name}}</mat-option>
          </mat-select>
          <button type="button" class="download-btn" (click)="downloadMaster()">Download Relation</button>
          <mat-slide-toggle class="togg" [color]="color" [checked]="sort_asc" (change)="changeOrder($event)">
            <span *ngIf="!sort_asc">Decending</span>
            <span *ngIf="sort_asc">Ascending</span>
          </mat-slide-toggle>

        </div>
      </div>

      <!-- <h4 class="r-mv" *ngIf="!selected_master">Master Value</h4> -->
      <!-- #6 Master group table list -->
      <ng-container *ngIf="selected_master">
        <div class="position-relative">
          <div class="table-estater table-estater-over" id="detect-scroll" (scroll)="onscroll($event)">
            <mat-table #table [sortablejs]="dataSource" [sortablejsOptions]="tableoptions" [dataSource]="dataSource">
              <!--- Note that these columns can be defined in any order.
                        The actual rendered columns are set as a property on the row definition" -->

              <!-- Serial No Column (Sno) -->
              <ng-container matColumnDef="sno">
                <mat-header-cell *matHeaderCellDef fxLayout="row" fxLayoutAlign="center center"> S.No.
                </mat-header-cell>
                <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center">
                  <mat-checkbox color="accent" class="" (change)="enableCopy($event, element)"
                    (click)="$event.stopPropagation()" [checked]="isChecked(element.id)"></mat-checkbox>
                  <span class="w-10">{{element.sno}}</span>
                </mat-cell>
              </ng-container>

              <!-- Master value id Column (master_value_id) -->
              <ng-container matColumnDef="master_value_id">
                <mat-header-cell *matHeaderCellDef fxLayout="row" fxLayoutAlign="center center"> Master value id
                </mat-header-cell>
                <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center">
                  <!-- <mat-checkbox color="accent" class="" (change)="enableCopy($event, element)"
                    (click)="$event.stopPropagation()" [checked]="isChecked(element.id)"></mat-checkbox> -->
                  <span class="w-10">{{element.master_value_id}}</span>
                </mat-cell>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
                  Master Value Name </mat-header-cell>
                <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
                  <div>{{element.name}}</div>
                  <!-- <div class="tex-cd">2</div>-->
                </mat-cell>
              </ng-container>

              <!-- language Column -->
              <ng-container matColumnDef="master_language">
                <mat-header-cell *matHeaderCellDef fxLayout="row" fxLayoutAlign="center center"> Master Value Label
                </mat-header-cell>
                <mat-cell *matCellDef="let element" [shAttachMenu]="menu" [shMenuData]="element.right_menu"
                  fxLayout="row" fxLayoutAlign="center center">
                  {{element.master_language}} </mat-cell>
              </ng-container>
              <!--
                        <ng-container matColumnDef="property_type">
                            <mat-header-cell *matHeaderCellDef fxLayout="row" fxLayoutAlign="center center"> Property Type
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center"> {{element.property_type}}
                            </mat-cell>
                        </ng-container> -->
              <ng-container matColumnDef="icon">
                <mat-header-cell *matHeaderCellDef fxLayout="row" fxLayoutAlign="center center"> Icon </mat-header-cell>
                <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center">
                  <img class="image-url" src="{{element.image_url}}" onerror="this.src='assets/images/banned.png';"
                    alt="">
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef fxLayout="row" fxLayoutAlign="center center">
                  Status</mat-header-cell>
                <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center">
                  <mat-checkbox color="accent" [disabled]="!mastervalues_access.PATCH"
                    (click)="$event.stopPropagation()" (change)="updateAttribute('status',element.id,$event)"
                    [checked]="element.status == '1'"></mat-checkbox>
                </mat-cell>
              </ng-container>

              <!-- Actions Column -->
              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef fxLayoutAlign="center center"> Action </mat-header-cell>
                <mat-cell *matCellDef="let element" fxLayoutAlign="center center">
                  <div fxLayout="row" fxLayoutAlign="center center">
                    <button *ngIf="mastervalues_access.PATCH" mat-icon-button
                      class="action-icon actionbtn justify-content-center"
                      (click)="$event.stopPropagation(); openDialog('mastervalue',{'groups':master_group_list,'edit':true,'master_val':element,'selected_master_value':selected_master_value}, $event)"
                      matTooltip="Edit">
                      <mat-icon>edit</mat-icon>
                    </button>
                    <!-- <button *ngIf="mastervalues_access.DELETE" mat-icon-button class="action-icon" matTooltip="Delete">
                    <mat-icon>delete</mat-icon>
                    </button>
                                    <button *ngIf="!mastervalues_access.DELETE && !mastervalues_access.PATCH" mat-icon-button class="action-icon">
                    <mat-icon>remove</mat-icon>
                    </button> -->
                  </div>
                </mat-cell>
              </ng-container>

              <mat-header-row class="skipdrag" *matHeaderRowDef="displayedColumns"></mat-header-row>
              <div class="rowss">
                <mat-row class="rowset draggableset"
                  [ngClass]="{active: isActivemasterVal(k), 'blue_bg': element.data_source == 3, 'green_bg': element.data_source == 4}"
                  *matRowDef="let row; columns: displayedColumns;let element;let k = index;"
                  (click)="selectedMasterValue(element,k, target)"></mat-row>
              </div>
            </mat-table>
            <div *ngIf="dataSource == 0">
              <mat-card class="p-4 text-center "> <strong>No Record Found</strong> </mat-card>
            </div>
            <!-- loader start -->
            <div *ngIf="loading" class='loader-div'>
              <ngx-loading [show]="loading"></ngx-loading>
            </div>
            <!-- loader end -->
          </div>
        </div>
      </ng-container>


      <!-- End of #6 Master group table list -->

      <div #target class="submasters">
        <div fxLayout="row" fxLayoutGap="10px" class="submaster-list" fxLayoutAlign="space-between center"
          *ngIf="selected_master_value">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <span><b>Sub Masters of {{selected_master_value?.master_language}}</b></span>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-select (selectionChange)="triggerSubmaster($event)" class="form-select-l"
                placeholder="Select an option">
                <mat-option *ngFor="let list of submasterlist; let k = index"
                  [value]="list.master_id">{{list.master_label[api.language] == undefined ? list.master_name :
                  list.master_label[api.language]}}
                </mat-option>
              </mat-select>
              <ng-container *ngIf="selected_list_item">
                <button matTooltip="Edit" class="custom-icn-btn"
                  (click)="openDialog('master',{'master_val':selected_list_item,'edit':true,'sub_master':true}, $event)">
                  <mat-icon>edit</mat-icon>
                </button>
              </ng-container>
              <button [disabled]="loader" matTooltip="Refresh list" (click)="triggerSubmaster('refresh')"
                class="refresh">
                <mat-icon>refresh</mat-icon>
              </button>
            </div>
          </div>
          <button *ngIf="selected_sub_master && mastervalues_access.POST" mat-button class="grey-button"
            (click)="openDialog('submastervalue', {}, $event)">
            Add {{selected_sub_master.master_label[api.language] == undefined ? selected_sub_master.master_name :
            selected_sub_master.master_label[api.language]}}
          </button>
        </div>

        <!-- #7 Sub master table list -->
        <div class="table-estater table-estater-wrapper table-estater-over" *ngIf="selected_sub_master">
          <mat-table #table [sortablejs]="sub_master_value_list" [sortablejsOptions]="tableoptionsSub"
            [dataSource]="sub_master_value_list">

            <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->

            <!-- Sno Column -->
            <ng-container matColumnDef="sno">
              <mat-header-cell *matHeaderCellDef fxLayout="row" fxLayoutAlign="center center"> S.No. </mat-header-cell>
              <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center"> {{element.sno}}
              </mat-cell>
            </ng-container>
            <!-- Sno Column -->
            <ng-container matColumnDef="master_value_id">
              <mat-header-cell *matHeaderCellDef fxLayout="row" fxLayoutAlign="center center"> Master value id </mat-header-cell>
              <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center">   <span class="w-10">
                {{element.id}}</span>
              </mat-cell>
            </ng-container>
            <!-- submaster name Column -->
            <ng-container matColumnDef="submaster">
              <mat-header-cell *matHeaderCellDef fxLayout="row" fxLayoutAlign="center center">
                {{selected_sub_master.master_label[api.language] == undefined ? selected_sub_master.master_name :
                selected_sub_master.master_label[api.language]}} Name </mat-header-cell>
              <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center"> {{element.name}}
              </mat-cell>
            </ng-container>

            <!-- language Column -->
            <ng-container matColumnDef="language">
              <mat-header-cell *matHeaderCellDef fxLayout="row" fxLayoutAlign="center center">
                {{selected_sub_master.master_label[api.language] == undefined ? selected_sub_master.master_name :
                selected_sub_master.master_label[api.language]}} Labels </mat-header-cell>
              <mat-cell *matCellDef="let element" [shAttachMenu]="menu" [shMenuData]="element.right_menu" fxLayout="row"
                fxLayoutAlign="center center">
                {{element.master_language}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef fxLayout="row" fxLayoutAlign="center center"> Status </mat-header-cell>
              <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center">
                <mat-checkbox color="accent" [disabled]="!mastervalues_access.PATCH"
                  (change)="updateSubAttribute('status',element.id,$event)"
                  [checked]="element.status == '1'"></mat-checkbox>
              </mat-cell>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef fxLayout="row" fxLayoutAlign="center center"> Actions
              </mat-header-cell>
              <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="start center" style="max-width: 12%;">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <button *ngIf="mastervalues_access.PATCH"
                    (click)="openDialog('submastervalue',{'edit':true,'sub_master':element}, $event)"
                    class="action-icon actionbtn justify-content-center" matTooltip="Edit">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button (click)="openConfirmation(element)" class="action-icon actionbtn"
                    matTooltip="unlink master value">
                    <mat-icon> call_split</mat-icon>
                  </button>
                  <!-- <button *ngIf="mastervalues_access.PATCH && mastervalues_access.DELETE" mat-icon-button
                  class="action-icon">
                  <mat-icon>remove</mat-icon>
                </button> -->
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row class="skipdrag" *matHeaderRowDef="displayedColumnssubmaster"></mat-header-row>
            <div>
              <mat-row class="rowset draggableset"
                [ngClass]="{'blue_bg': element.data_source == 3, 'green_bg': element.data_source == 4}"
                *matRowDef="let row; columns: displayedColumnssubmaster;let element;"></mat-row>
            </div>
          </mat-table>
          <div *ngIf="sub_master_value_list == 0">
            <mat-card class="p-4 text-center "> <strong>No Record Found</strong> </mat-card>
          </div>
          <!-- loader start -->
          <div *ngIf="loading_sub_master" class='loader-div'>
            <ngx-loading [show]="loading_sub_master"></ngx-loading>
          </div>
          <!-- loader end -->
        </div>
        <!-- End of #7 Sub master table list -->
      </div>

    </div>
  </div>
</div>
