/**
 * Component: ManagePriceUploadViewComponent
 *
 * Description:
 * This component is responsible for managing the uploaded price data view.
 * It interacts with the backend API to fetch, display, and manage price data records.
 * The component also provides functionalities to download templates and handle pagination.
 * It emits events to notify the parent component about certain actions.
 *
 * Key Features:
 * - Fetch and display uploaded price data.
 * - Manage pagination with customizable limit and offset.
 * - Download a price data template from the server.
 * - Emit events to inform the parent component.
 */

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { ApiserviceService } from "../../../../../../src/app/apiservice.service";
import { NotificationService } from "../../../../../../src/app/admin/services/notification.service";
import { MatDialog } from "@angular/material/dialog";
import { PriceRemovalConfirmationComponent } from "../price-removal-confirmation/price-removal-confirmation.component";
import { STATUS_MAP } from "../../models/price-status.model";
import { ManagePriceFilterComponent } from "../manage-price-filter/manage-price-filter.component";

@Component({
  selector: "app-manage-price-upload-view",
  templateUrl: "./manage-price-upload-view.component.html",
  styleUrls: ["./manage-price-upload-view.component.scss"],
})
export class ManagePriceUploadViewComponent {
  @ViewChild(ManagePriceFilterComponent)
  ManagePriceFilterComponent!: ManagePriceFilterComponent;
  @Input() resourceAccess: any;
  @Output() resetFilters = new EventEmitter<void>(); // Notify parent to reset
  @Output() changeToDefault = new EventEmitter<void>(); //EventEmitter to notify the parent component to reset to the default state.
  totalRecords: number = 0; // Total number of records fetched from the API.
  selectedData: [] = []; // Holds the selected data from the table (if applicable).
  selectedRows: any[] = [];
  selectedFilterColumn = [];
  receivedFilterData;
  isLoading: boolean = false; // Loading state to indicate API calls in progress.
  //Table columns to display data.
  tableColumn: string[] = [
    "checkbox",
    "transaction_id",
    "entity_type",
    "property_uid",
    "unit_uid",
    // 'category',
    "transaction_type",
    "transaction_value",
    "transaction_date",
    "outlier",
    // "floor_number",
    "prop_type_govt",
    "prop_type_estater",
    "source_type",
    "year",
    "transaction_area",
    "source_name",
    "trans_government_id",
    "remark",
    "added_date",
    "added_by",
    "updated_date",
    "updated_by",
    // "status",
  ];

  uploadedPriceDataList: any[] = []; // List of uploaded price data fetched from the API.
  //Status mapping object to define labels and color codes for different statuses.
  statusMap = STATUS_MAP;
  limit: number = 10; //Number of records to fetch per page.
  offset: number = 0; // Offset for paginated API requests.
  isFiltersPanelOpen = false;
  badgeCount: number;
  chipsLabel: any;
  emittedIndex: number | null = null;
  selectedFilterOptions: any;
  constructor(
    private apiService: ApiserviceService, // Service to handle API requests
    private cdr: ChangeDetectorRef, // Change detector to manage view updates
    private notify: NotificationService,
    public dialog: MatDialog
  ) {}

  /**
   * Lifecycle hook: Initializes the component and fetches initial data.
   */
  ngOnInit(): void {
    this.fetchUploadedPriceData();
  }

  /**
   * Fetches the uploaded price data from the API.
   * Handles loading state and updates the view upon success or failure.
   */
  fetchUploadedPriceData(): void {
    let apiUrl = `/transaction-upload/view-data?limit=${this.limit}&offset=${this.offset}&is_count=true`;

    // Add filter parameters if available
    if (this.receivedFilterData && Object.keys(this.receivedFilterData).length > 0) {
      const filterParam = encodeURIComponent(JSON.stringify(this.receivedFilterData));
      apiUrl += `&filter=${filterParam}`;
    }

    this.isLoading = true;
    this.apiService.getEpsData(apiUrl).subscribe({
      next: (response: any) => {
        this.selectedRows = null;
        this.isLoading = false;
        this.uploadedPriceDataList = [...response.data];
        this.totalRecords = response.totalRecord;
        this.extractFirstObjectKeys();
        this.cdr.detectChanges();
      },
      error: (error) => {
        console.error("API Error:", error);
        this.isLoading = false;
      },
    });
  }


  // Method to extract keys of the first object
  extractFirstObjectKeys() {
    if (this.uploadedPriceDataList && this.uploadedPriceDataList.length > 0) {
      this.selectedFilterColumn = Object.keys(
        this.uploadedPriceDataList[0]
      ).filter((key) => key !== "floor_number" && key !== "floor_uid");
    }
  }

  /**
   * Downloads the price data template from the server.
   */
  downloadTemplate() {
    let apiUrl = `download-transaction-data?user_id=${this.apiService.user_id}`;
    if (
      this.receivedFilterData &&
      Object.keys(this.receivedFilterData).length > 0
    ) {
      // Serialize the object into a JSON string
      const filterParam = encodeURIComponent(
        JSON.stringify(this.receivedFilterData)
      );
      apiUrl += `&filter=${filterParam}`;
    }
    this.isLoading = true;
    this.apiService.getEpsData(apiUrl).subscribe({
      next: (response: any) => {
        this.isLoading = false;
        window.open(response.data, "_blank");
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
      },
    });
  }

  /**
   * Handles pagination changes and fetches the updated data.
   * @param event - Pagination event containing the updated limit and offset.
   */
  pageChangeAction(event: { limit: number; offset: number }) {
    this.limit = event.limit;
    this.offset = event.offset;
    this.fetchUploadedPriceData();
  }

  /**
   * Emits an event to notify the parent component to close the child component.
   * @param value - Optional value to pass to the parent component.
   */
  closeComponent(value?: any): void {
    this.changeToDefault.emit(value);
  }
  onSelectedRowsChange(selectedRows: any[]) {
    this.selectedRows = selectedRows;
  }

  /**
   * Sends a deletion request and opens a confirmation dialog.
   * @param
   */
  deleteRequest() {
    if (!this.resourceAccess.DELETE) {
      this.notify.notify(
        "Access denied: Unauthorized deletion of price transaction data.",
        "warn"
      );
      return;
    }
    let dialogRef = this.dialog.open(PriceRemovalConfirmationComponent, {
      width: "450px",
      panelClass: "create-master-panel",
      disableClose: true,
      data: {
        property_price_ids: this.selectedRows.map(
          (item) => item.transaction_id
        ),
        user_id: this.apiService.user_id,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selectedRows = null;
        this.fetchUploadedPriceData();
        this.notify.notify(
          "Price transaction data has been successfully deleted",
          "success",
          5000
        );
      }
    });
  }
  // Method to handle the received data from the child
  handleReceivedData(data: any[]) {
    let transformedObject: { [key: string]: any } = {};

    // Ensure data is an array
    if (Array.isArray(data)) {
      data.forEach((item) => {
        // Check if the item has the necessary properties directly
        if (item.columnName && item.columnValue) {
          // Assign columnName as key and columnValue as value
          transformedObject[item.columnName] = item.columnValue;
        } else {
          console.error("Missing columnName or columnValue in item:", item);
        }
      });
      this.receivedFilterData = { ...this.receivedFilterData, ...transformedObject };
      this.fetchUploadedPriceData();
    }
  }


  openFiltersPanel() {
    this.isFiltersPanelOpen = true;
  }

  closeFiltersPanel() {
    this.isFiltersPanelOpen = false;
  }
  // filterApply(event) {
  //   console.log("filterApply is calling after remove row", event);
  //   this.handleReceivedData(event);
  //   this.badgeCount = event.length;
  //   this.chipsLabel = event.map((item) => item.columnName);

  //   this.closeFiltersPanel();
  // }

  filterApply(event): void {
    console.log("filterApply is called:", event);

    // Update badge count and chips label
    this.badgeCount = event.length;
    this.chipsLabel = event.map(item => item.columnName);

    // Update received filter data and fetch filtered data
    this.receivedFilterData = {};
    event.forEach(item => {
      if (item.columnName && item.columnValue) {
        this.receivedFilterData[item.columnName] = item.columnValue;
      }
    });

    this.fetchUploadedPriceData();
    this.closeFiltersPanel();
  }
  // resetFilter() {
  //   this.receivedFilterData = null;
  //   this.badgeCount = 0;
  //   this.chipsLabel = null;
  //   this.fetchUploadedPriceData();
  //   this.closeFiltersPanel();
  // }
  resetFilter(): void {
    this.badgeCount = 0;
    this.chipsLabel = [];
    this.receivedFilterData = {};
    this.fetchUploadedPriceData();
    this.closeFiltersPanel();
  }

  removeRowFromChips(index: number): void {
    if (this.ManagePriceFilterComponent) {
      this.ManagePriceFilterComponent.removeRowFromChips(index);
    } else {
      console.error("Child component not available.");
    }
  }

  handleFilterOptionsChanged(options: any): void {
    console.log("handleFilterOptionsChanged is calling after remove row", options);

    this.selectedFilterOptions = options;
    this.filterApply(options);
  }
  getDateRange(event: [string, string]) {
    console.log(event, "event date range");

    // Add the date range as an array to receivedFilterData
    this.receivedFilterData['date'] = event;
    console.log(this.receivedFilterData, "Updated Filter Data in getDateRange");
  }

}
