<!--
  This template represents a confirmation dialog for deleting a price transaction.
  It allows the user to confirm or cancel the deletion request. The form includes a comment field
  to provide additional remarks on the deletion. The comment field has a validation that ensures
  a minimum of 3 words are entered. The dialog includes the following elements:

  1. **Confirmation Header**: Displays a title and a close button to close the dialog.
  2. **Message**: Informs the user about the irreversible action of deleting a price transaction.
  3. **Form**: Contains a text area for entering a comment with a validation rule (minimum 3 words).
     The form uses Angular's reactive forms, with a `deleteRequestGroup` FormGroup and a `comment` FormControl.
  4. **Submit and Cancel Buttons**:
     - The "Yes" button triggers the `deletePriceRequest()` method and is disabled until the form is valid.
     - The "Cancel" button triggers the `close()` method to close the dialog without any action.

  **Key Features:**
  - **Close Button**: Allows the user to dismiss the dialog without taking action.
  - **Textarea for Comment**: Used to enter a comment, with a maximum character limit of 180.
  - **Validation**: Ensures the comment is at least 3 words long.
  - **Submit Button**: Only enabled when the form is valid (comment field meets the validation).

  **Notes:**
  - The template uses Angular Material components such as `mat-toolbar`, `mat-form-field`, and `mat-error` to provide a clean and responsive UI.
  - The dialog is expected to be part of a component that includes methods for handling the form submission (`deletePriceRequest()`) and closing the dialog (`close()`).

-->

<div class="confirmation-wrapper">
  <!-- Toolbar Header with Close Button -->
  <mat-toolbar class="matpopup-header">
    <div>Confirm Deletion</div>
    <button type="button" class="close-btn" (click)="close()">
      <img src="../../../../../assets/icon/Cross.svg" alt="Close">
    </button>
  </mat-toolbar>

  <!-- Confirmation Message -->
  <p class="mesg-text">
    Are you sure you want to delete the price transaction? This action cannot be <b>undo</b>.
  </p>

  <!-- Form for Comment -->
  <form [formGroup]="deleteRequestGroup">
    <mat-form-field class="example-full-width" appearance="outline" floatLabel="always">
      <mat-label>Leave a comment</mat-label>
      <textarea matInput #commentMessage placeholder="Write comment..." formControlName="comment" maxlength="180"></textarea>

      <!-- Error Message for Minimum Word Validation -->
      <mat-error *ngIf="comment.errors?.minWords">
        You need to enter at least 3 words.
      </mat-error>

      <!-- Character Count -->
      <mat-hint>{{ commentMessage.value.length }}/180</mat-hint>
    </mat-form-field>
  </form>

  <!-- Confirmation and Cancel Buttons -->
  <div class="center gap-24">
    <button class="cnfrm-btn" (click)="deletePriceRequest()" [disabled]="deleteRequestGroup.invalid">
      Yes
    </button>
    <button class="cancel" (click)="close()">
      Cancel
    </button>
  </div>
</div>
