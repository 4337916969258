<mat-toolbar *ngIf="!data.edit" class="matpopup-header">
    <div>Add {{data.pObj.selected_sub_master.master_label[api.language]}}</div>
</mat-toolbar>
<mat-toolbar *ngIf="data.edit" class="matpopup-header">
    <div>Edit {{data.pObj.selected_sub_master.master_label[api.language]}}</div>
</mat-toolbar>

<form [formGroup]="subMasterValueForm" (ngSubmit)="addSubmasterValue(subMasterValueForm,$event)">
    <mat-dialog-content>
        <!-- select for selecting from all sub master values -->
        <mat-form-field floatLabel="never" class="w-100">
            <mat-select (selectionChange)="getSelectedOption($event)" [(ngModel)]="checkedSubmasterValues" [ngModelOptions]="{standalone: true}" multiple placeholder="Select from exisiting Master Value">
                <mat-option *ngFor="let value of allSubmasterVal" [value]="value.id">{{value.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field floatLabel="never" class="w-100">
            <input matInput type="text" [errorStateMatcher]="matcher" formControlName="name" maxlength="64" #sub_master_value placeholder="Submaster Value" value="">
            <mat-hint align="end"> {{sub_master_value.value.length}}/64 </mat-hint>
            <mat-error>Group is required</mat-error>
        </mat-form-field>
        <div class="form-input-container" fxLayout="row" fxLayoutAlign="space-between start">
            <div fxFlex="100%" fxLayout="column" formArrayName="language">
                <div *ngFor="let languagevalues of language_valueset;let kk = index">
                    <div [formGroupName]="kk" fxLayout="row" fxLayoutAlign="space-between start" class="gap-10">
                        <mat-form-field floatLabel="never" fxFlex="40%">
                            <mat-select formControlName="language" name="language" placeholder="Language">
                                <mat-option *ngFor="let language of languages" [value]="language.language_id">
                                    {{ language.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field floatLabel="never" fxFlex="40%">
                            <input type="text" matInput formControlName="value" #sub_master_label maxlength="64" [errorStateMatcher]="matcher" name="value" placeholder="Label" />
                            <mat-error *ngIf="!subMasterValueForm.controls.language.controls[kk].controls.value.valid">
                                Label is required
                            </mat-error>
                            <mat-hint align="end"> {{sub_master_label.value.length}}/64 </mat-hint>
                        </mat-form-field>
                        <div class="addbtn">
                          <div (click)="removeRow(kk)" class="border iconTick-1">
                              <mat-icon>remove</mat-icon>
                          </div>
                          <div (click)="addRow()" class="border iconTick-1">
                              <mat-icon>add</mat-icon>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <mat-slide-toggle class="example-margin" [checked]="status" [(ngModel)]="status" [ngModelOptions]="{standalone: true}">
            Status
        </mat-slide-toggle>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" class="dialog-actions">
        <button type="submit" mat-raised-button class="blue-button">Submit</button>
        <button type="button" mat-raised-button (click)="closeDialog($event)">Cancel</button>
    </mat-dialog-actions>
</form>
