import { Component, OnInit, ViewEncapsulation, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { ApiserviceService } from '../../apiservice.service';
import { Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AppNotificationService } from '../services/app-notification.service';
import { FormControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';


/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>umsapi_place_get</li>
 * <li>gisapi_cities_json_get</li>
 * </ol>
 *
 */
@Component({
    selector: 'city-dialog',
    templateUrl: './city-select-component.html',
    styleUrls: ['./city-select-component.scss'],
})
export class SelectCityComponent {
    selectedcity;
    citieslist = [];
    enablesubmit: boolean;

    search_param='';
    searchFilter=new FormControl();
    protected _onDestroy = new Subject<void>();

    constructor(private api: ApiserviceService, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
        private router: Router) {
        iconRegistry.addSvgIcon('Kuwait', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/Kuwait.svg'))
            .addSvgIcon('Dubai', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/Dubai.svg'))
            .addSvgIcon('Bahrain', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/Bahrain.svg'))
            .addSvgIcon('Riyadh', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/Riyadh.svg'))
            .addSvgIcon('Sharjah', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/Sharjah.svg'));

        localStorage.removeItem("city_id");
        localStorage.removeItem('city_name');
        localStorage.removeItem('city_code');
        localStorage.removeItem('city_detail');
        this.getCityList();
    }

    getCityList() {
        this.api.getUmsData('country-with-city')
            .subscribe(
                {next: (res: any) => {
                    let tempList = [];
                    res.data.forEach((element) => {
                      if (element.status == 1) {
                        if(element?.child_data && element?.child_data?.length > 0){
                         element.child_data.map(e=>{
                             e['parent_name']=e?.country_name;
                             e['search_param']=e.place_name+' '+e?.country_name;
                             tempList.push(e);
                         })
                        }else{
                         element['search_param']=element.place_name;
                         tempList.push(element);
                        }

                     }
                    });
                    this.citieslist = tempList;
            },
            error:(err)=>{
            }
        });
    }

    selectCity(val) {
        localStorage.setItem('city_name', val.place_name);
        this.selectedcity = {
            city_id: val.place_id,
            city_name: val.place_name,
            place_code: val.place_code,
            lat: val.latitude,
            lon: val.longitude
        };
        this.api.city_code = this.selectedcity.place_code;
        this.submitCity();
        if(this.api.user_id && this.api.city_id){
          //
          this.api.getGoogleMapKey();
        }
        //   this.enablesubmit = true;
    }

    submitCity() {
        if (this.selectedcity.city_id) {
            // this.getCityDetail();
            localStorage.setItem('city_detail', JSON.stringify(this.selectedcity));
            localStorage.setItem("city_id", this.selectedcity.city_id);
            if (this.selectedcity.place_code) localStorage.setItem('city_code', this.selectedcity.place_code);
            this.api.city_id = this.selectedcity.city_id;
            setTimeout(() => {
                this.api.getAllowResources();
            }, 100);
            this.api.getUserDetail(this.api.user_id);
            this.api.getUserRole()
            this.router.navigateByUrl('/admin');

        }
    }

    //logout
    logout(){
        this.api.logout(false);
    }

    ngOnInit() {
        this.searchFilter.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe((val) => {
          this.search_param = val;
        });
      }
      ngOnDestroy() {

      }
}
