<!-- #1 For Web view-->
<div>
  <div fxLayout="row" class="w-100 crumb-c" fxLayoutAlign="space-between center">
    <div fxLayout="row" class="w-100 bleft" fxLayoutAlign="space-between center">
      <admin-breadcrumb [total_records]="total_property_records"></admin-breadcrumb>
      <div class="filterstatus">
        <mat-label>Status: </mat-label>
        <mat-select [(ngModel)]="status_filter" (selectionChange)="filterStatus(status_filter)" class="form-select-l"
          style="margin-right: 12px;">
          <mat-option *ngFor="let filter of status_filter_values" [value]="filter.value">
            {{filter.alias}}</mat-option>
        </mat-select>
        <input [ngClass]="{'animated fadeIn' :showsearchbar}" [(ngModel)]="searchType"
          class="form-input-l search-inputbox" placeholder="Type to search..." type="text">
        <mat-icon class="icon-mat-search">search</mat-icon>
      </div>

      <!--<div fxLayout="row" fxLayoutAlign="end center">
        <div fxLayout="row"  *ngIf="showsearchbar" fxShow="false" fxShow.gt-xs="true" fxLayoutAlign="start center" class="search-bar">
            <button mat-icon-button (click)="showSearch()"><mat-icon>close</mat-icon></button>

        </div>
        <div fxLayout="row" fxShow="false" *ngIf="!showsearchbar" fxShow.gt-xs="true" fxLayoutAlign="start center" fxLayoutGap="10px" class="search-bar">
            <button mat-icon-button (click)="showSearch()">
              <mat-icon>search</mat-icon>
          </button>
      </div>
      </div>-->
    </div>
    <div fxLayout="row" class="cleft" fxLayoutAlign="end center">
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" class="button-b">
        <button *ngIf="properties_access.POST" mat-button class="grey-button"
          (click)="openDialog('createattribute')">Create Property type</button>
      </div>
    </div>
  </div>
  <div class="act-container" fxLayout="column">
    <!-- <h4 class="r-mv">Property Value</h4> -->

    <!-- #6 Master group table list -->
    <div class="table-estater table-estater-wrapper">

      <mat-table #table [dataSource]="dataSource | searchfilter:'name':searchType" [sortablejs]="dataSource"
        [sortablejsOptions]="tableoptions">
        <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

        <!-- Serial No Column (Sno) -->
        <ng-container matColumnDef="sno">
          <mat-header-cell *matHeaderCellDef fxLayout="row" fxFlexAlign="stretch" fxLayoutAlign="center center"> S.No.
          </mat-header-cell>
          <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center"> {{element.sno}}
          </mat-cell>
        </ng-container>
        <!-- Property Type Column -->
        <ng-container matColumnDef="property_type_id">
          <mat-header-cell *matHeaderCellDef fxLayout="row" fxFlexAlign="stretch" fxLayoutAlign="center center">
            Property Type Id</mat-header-cell>
          <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center">
            {{element.id}}
          </mat-cell>
        </ng-container>

        <!-- Property Type Column -->
        <ng-container matColumnDef="property_type">
          <mat-header-cell *matHeaderCellDef fxLayout="row" fxFlexAlign="stretch" fxLayoutAlign="center center">
            Property Type </mat-header-cell>
          <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center"
            [matTooltip]="(element.name.length > 30)? element.name :''"> {{(element.name.length > 30) ? (element.name |
            slice:0:30)+'...': element.name}}
          </mat-cell>
        </ng-container>

        <!-- Propert Language Column -->
        <ng-container matColumnDef="property_label">
          <mat-header-cell *matHeaderCellDef fxLayout="row" fxFlexAlign="stretch" fxLayoutAlign="center center">
            Property Label </mat-header-cell>
          <mat-cell *matCellDef="let element" [shAttachMenu]="element.right_menu" fxLayout="row"
            fxLayoutAlign="center center" [matTooltip]="(element.label.length > 30)? element.label :''">
            {{(element.label.length > 30) ? (element.label | slice:0:30)+'...': element.label}}</mat-cell>
        </ng-container>

        <!-- Propert Status Column -->
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef fxLayout="row" fxFlexAlign="stretch" fxLayoutAlign="center center"> Status
          </mat-header-cell>
          <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center">
            <!-- <mat-checkbox disabled [checked]="element.status"></mat-checkbox> -->
            <!-- <span [class.text-success]="element.status == 1" [class.text-danger]="element.status == 0">{{element.status == 1 ? 'Active':
                            'Inactive'}}</span> -->
            <mat-slide-toggle class="example-margin" [checked]="element.status == 1"
              [ngModelOptions]="{standalone: true}"
              (change)="changeStatus(element.id, $event, element.status)"></mat-slide-toggle>
          </mat-cell>
        </ng-container>


        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef fxLayoutAlign="center center"> Action </mat-header-cell>
          <mat-cell *matCellDef="let element" fxLayoutAlign="center center">
            <div fxLayout="row" fxLayoutAlign="center center">
              <button [disabled]="!properties_access.PATCH" class="action-icon actionbtn"
                (click)="openDialog('createattribute',{'edit':true,'val':element})" matTooltip="Edit">
                <mat-icon>edit</mat-icon>
              </button>
              <!-- <button [disabled]="!properties_access.DELETE" mat-icon-button class="action-icon"
                                matTooltip="Delete">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <button [disabled]="!properties_access.PATCH || !properties_access.DELETE" mat-icon-button
                                class="action-icon">
                                <mat-icon>remove</mat-icon>
                            </button> -->
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row class="skipdrag" *matHeaderRowDef="displayedColumns"></mat-header-row>
        <div class="rowss">
          <mat-row class="rowset draggableset" *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </div>
      </mat-table>
      <div *ngIf="(dataSource | searchfilter: 'name': searchType)?.length === 0">
        <mat-card class="p-4 text-center "> <strong>No Record Found</strong> </mat-card>
      </div>
    </div>
    <!-- End of #6 Master group table list -->

    <!-- loader start -->
    <div *ngIf="loader" class='loader-div'>
      <ngx-loading [show]="loader"></ngx-loading>
    </div>
    <!-- loader end -->

  </div>
</div>
<!-- loader start -->
<div *ngIf="ready_load">
  <ngx-loading [show]="ready_load"></ngx-loading>
</div>
<!-- loader end -->

<!-- end of #1 For Web view-->

<!-- #2 For Mobile Screen (Search and Add)-->
<!-- <div fxLayout="column" class="topb" fxShow="true" fxShow.gt-sm="false">
    <div fxLayout="row" class="w-100" fxLayoutAlign="space-between center">
        <div class="w-100">
            <admin-breadcrumb></admin-breadcrumb>
        </div>
    </div>
    <div fxLayout="row" *ngIf="!showmobilesearchbar" class="w-100 master-middle-bar" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
            <div fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="space-around start">
              <label class="small-search-label">Property type</label>
              <select class="form-select-white">
                <option>Apartment</option>
              </select>
            </div>
            <div fxLayout="column" fxLayoutGap="5px"  fxLayoutAlign="space-around start">
                <label class="small-search-label">Entity type</label>
                <select class="form-select-white">
                  <option>Property</option>
                </select>
              </div>
        </div>
        <div fxLayout="row" *ngIf="!showsubmas" fxLayoutGap="5px" fxLayoutAlign="end center">
            <button mat-icon-button (click)="openDialog('createattribute')" class="greenbutton">
                <mat-icon>add</mat-icon>
              </button>
            <button mat-icon-button class="obutton" (click)="showMobileSearchBar()">
                  <mat-icon>search</mat-icon>
              </button>
        </div>
    </div>

    <div fxLayout="row" *ngIf="showmobilesearchbar" [ngClass]="{'animated fadeIn': showmobilesearchbar}" class="w-100 master-middle-bar" fxLayoutAlign="space-between center">
        <div fxFlex="90%">
            <input type="text" class="mobile-searchbar" placeholder="Search Property" />
        </div>
        <div fxFlex="10%">
            <button mat-icon-button (click)="showMobileSearchBar()" class="obutton">
                <mat-icon>close</mat-icon>
              </button>
        </div>
    </div>
</div> -->


<!-- For Mobile Screen-->
<!--
<div class="container listview" fxShow="true" fxShow.gt-sm="false" fxLayout="row">
    <div fxLayout="column" class="w-100" fxLayoutGap="15px" fxLayoutAlign="space-between center">
        <div class="pack w-100">
            <div class="draggableset sets" *ngFor="let source of dataSource;let s = index">

                <div fxLayout="column" fxLayoutAlign="center start" class="les" id="les{{s}}" (swipeleft)="swipeleftactions(source,s)" (swiperight)="swiperightactions(source,s)">

                    <div class="heading" fxFlex="100%" [ngClass]="{active : mobiletablistselect == s}">
                        {{s+1}}. {{source.property_type}}
                    </div>
                    <div class="middlec" fxLayout="row" fxLayoutAlign="space-around center">
                        <div fxLayout="column" fxFlex="100%">
                            <div class="subtext">
                                <span class="label">Language : </span>
                                <span class="value">{{source.property_language}}</span>
                            </div>
                        </div>

                    </div>
                    <div class="swipeactions">
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <button mat-button class="swipe-button"><mat-icon>edit</mat-icon></button>
                            <button mat-button class="swipe-button"><mat-icon svgIcon="delete-icon"></mat-icon></button>
                        </div>
                        <button mat-button class="submaster">Submaster</button>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div> -->

<!-- For Mobile Screen-->

<!-- End of #2 Mobile Screen -->
