import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
} from "@angular/core";
import { AdminBreadcrumbService } from "../admin-breadcrumb/services/admin-breadcrumb.service";
import { AdminMenusService } from "../admin-sidebar/admin-menus.service";
// import { SortablejsOptions } from 'ngx-sortablejs';
import { FormControl } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import * as $ from "jquery";
import { debounceTime } from "rxjs";
import { Options } from "sortablejs";
import { ApiserviceService } from "../../apiservice.service";
import { CommonfunctionService } from "../../services/commonfunction.service";
import { MatErrorStateMatcher } from "../admin-error-state-macher";
import { NotificationService } from "../services/notification.service";
import { CreateAttributeDialog } from "./components/create-update-attribute/admin-createattribute.component";
import { AttributeDialog } from "./components/copy-attribute-dialog/attribute-dialog.component";

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>umsapi_user_resource_post</li>
 * <li>estapi_entities_get</li>
 * <li>estapi_propertytypes_get</li>
 * <li>estapi_entity_attributes_get</li>
 * <li>estapi_entity_attributes_patch</li>
 * <li>estapi_attribute_measureunittypes_get</li>
 * <li>estapi_attribute_datatypes_get</li>
 * <li>estapi_masters_get</li>
 * </ol>
 *
 */

@Component({
  selector: "app-admin-attribute",
  templateUrl: "./admin-attribute.component.html",
  styleUrls: ["./admin-attribute.component.scss"],
})
export class AdminAttributeComponent implements OnInit {
  showCopy: boolean = false;
  selected_attr_list: any = [];
  dataSource = [];
  customize_attribute = [];
  all_customize_attribute: any;
  attributes_list = [];
  group_attribute = [];
  searchText = "";
  used_attribute = [];
  searchField: FormControl;
  load: boolean = false;
  sortField: string = '';
  sortOrder: string = 'asc';
  displayedColumns = [
    "sno",
    "name",
    "property_type",
    "parent_name",
    "data_type",
    "is_unique",
    "frequent_search",
    "is_language",
    "associated_master",
    "Description",
    "actions",
  ];
  tableoptions: Options = {
    draggable: ".draggableset",
  };
  attributes_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };
  property_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };
  entity_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };
  showsearchbar: boolean = false;
  showmobilesearchbar: boolean = false;
  loader: boolean;
  measure_unit_type = [];
  data_type = [];
  master_list = [];
  selected_property_type: number;
  selected_entity_type: string;
  search_input: string;
  is_property_dependent: boolean;
  is_unique: boolean;
  is_frequently_search: boolean;
  is_language_dependent: boolean;
  ready_load: boolean = false;
  sno = 0;
  master_dependent = "single-select,multi-select,table,table-row";
  isAssoMast: number = null;
  associated_masterId: number | null = null;
  isproType: number = null;
  desable: boolean = true;
  temp: any;
  height: number;
  matcher = new MatErrorStateMatcher();
  total_records: any = {
    total: 0,
    parent: 0,
    child: 0,
    mutual: 0,
  };
  childItems: any;
  constructor(
    public dialog: MatDialog,
    private menus: AdminMenusService,
    private _crumbs: AdminBreadcrumbService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private _el: ElementRef,
    public api: ApiserviceService,
    private fnc: CommonfunctionService,
    private notify: NotificationService,
    private route: Router
  ) {
    this.menus.setActive("attributes");
    this._crumbs.clear();
    this._crumbs.addcrumb = { title: "Attributes" };
    this._crumbs.mobiletitle = "Attributes";
    this._crumbs.count = 1;
    iconRegistry
      .addSvgIcon(
        "edit-icon",
        sanitizer.bypassSecurityTrustResourceUrl(
          "assets/images/svgicons/edit.svg"
        )
      )
      .addSvgIcon(
        "delete-icon",
        sanitizer.bypassSecurityTrustResourceUrl(
          "assets/images/svgicons/delete.svg"
        )
      )
      .addSvgIcon(
        "move-icon",
        sanitizer.bypassSecurityTrustResourceUrl(
          "assets/images/svgicons/move.svg"
        )
      );
    this.getAllowResourcesApi();
    this.getMeasureUnitType();
    this.getDataType();
    this.onResize();
  }
  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.height = window.innerHeight - 128;
  }
  // getting allow resources
  getAllowResourcesApi() {
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`;
    this.api.getUmsData(body).subscribe({
      next: (res: any) => {
        this.api.allowResources = res.data;
        this.getAllowResource();
        this.getMasters();
        this.getEntityType();
      },
      error: (err) => {
      },
    });
  }
  // getAllowResourcesApi() {
  //   let body = "place_id=" + this.api.city_id;
  //   this.api.postUmsData('user/resource', body)
  //     .subscribe(
  //       (data: any) => {
  //         this.api.allowResources = data;
  //         this.getAllowResource();
  //         this.getMasters();
  //         this.getEntityType();
  //       },
  //       err => {
  //       }
  //     )
  // }

  // enable disable copy icon
  enableCopy(e, data) {
    if (e.checked == true) {
      this.selected_attr_list.push(data.id);
    } else {
      this.selected_attr_list.splice(
        this.selected_attr_list.indexOf(data.id),
        1
      );
    }
    if (this.selected_attr_list.length) {
      this.showCopy = true;
    } else {
      this.showCopy = false;
    }
  }

  pasteAttrDialog() {
    if(!this.attributes_access.POST){
    this.notify.notify("You are not authorized to copy attribute","warn");
    return
    }
    let dialogRef = this.dialog.open(AttributeDialog, {
      width: "350px",
      panelClass: "create-master-panel",
      data: { parent: this }, //optional
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  ngOnInit() {
    let property_search;
    let text;
    let seachString;
    this.searchField = new FormControl();
    this.searchField.valueChanges
      .pipe(debounceTime(1000)) // Debounce input for 1 second
      .subscribe((term: string) => {
        term = term.trim().toLowerCase();
        this.search_input = term;
        if (this.is_property_dependent) {
      property_search = "&property_type_ids=" + this.selected_property_type;
    }
    if (this.search_input) {
      text = "&attribute_name=" + this.search_input;
    }
    seachString = property_search + text;

        this.getAttributes(this.sortField, 10000, 0, this.sortOrder, seachString);
      });
    // this.searchField.valueChanges.pipe(debounceTime(1000)).subscribe((term) => {
    //   term = term.trim().toLowerCase();
    //   this.search_input = term;
    //   if (term) {
    //     let flag = false;
    //     let res = [];
    //     this.all_customize_attribute.forEach((v) => {
    //       flag = false;
    //       if (v.name && v.name.toLowerCase().search(term) != -1) {
    //         flag = true;
    //       }
    //       if (v.child && v.child.length) {
    //         v.child.forEach((c) => {
    //           if (c.name.toLowerCase().search(term) != -1) {
    //             flag = true;
    //           }
    //           if (c.mutual.length) {
    //             c.mutual.forEach((m) => {
    //               if (m.name.toLowerCase().search(term) != -1) {
    //                 flag = true;
    //               }
    //             });
    //           }
    //         });
    //       }
    //       if (v.mutual && v.mutual.length) {
    //         v.mutual.forEach((m) => {
    //           if (m && m.name && m.name.search(term) != -1) {
    //             flag = true;
    //           }
    //         });
    //       }
    //       if (flag) {
    //         res.push(v);
    //       }
    //     });
    //     this.customize_attribute = res;
    //   } else {
    //     this.customize_attribute = this.all_customize_attribute;
    //   }
    // });
  }

  // getting list of entity type
  getEntityType() {
    this.api.getEmsData("entities").subscribe((data: any) => {
      this.api.entity_type_list = data;
      this.getPropertyType();
      this.ready_load = true;
    });
  }
  // getting list of property type
  getPropertyType(sort_by = "property_type_name", limit = 10000, offset = 0) {
    let url =
      "propertytypes?sort_by=" +
      sort_by +
      "&limit=" +
      limit +
      "&offset=" +
      offset +
      "&status=1";
    this.api.getEmsData(url).subscribe((data: any) => {
      this.api.property_type_list = data;
      this.selected_entity_type = this.api.entity_type_list[0]?.entity_name;
      this.is_property_dependent =
        this.api.entity_type_list[0]?.is_property_type_dependent;
      this.selected_property_type =
        this.api.property_type_list[0]?.property_type_id;
      this.searchAttribute(false);
    });
  }

  searchAttribute(val) {
    let seachString = "",
      property_search = "",
      text = "";
      this.sortField = '';
      this.sortOrder = '';
    this.search_input = null;
    this.searchField.setValue("");
    let entity_info = this.fnc.getArrayValue(
      "entity_name",
      this.selected_entity_type,
      this.api.entity_type_list || []
    );
    this.is_property_dependent = entity_info?.is_property_type_dependent;
    if (this.is_property_dependent) {
      property_search = "&property_type_ids=" + this.selected_property_type;
    }
    if (this.search_input) {
      // not in use for now
      text = "&attribute_name=" + this.search_input;
    }
    seachString = property_search + text;
    this.getAttributes("attribute_name", 10000, 0, seachString);
  }
  isChecked(id) {
    if (this.selected_attr_list.includes(id)) {
      return true;
    } else {
      return false;
    }
  }

  sortTable(field: string): void {
    let seachString = "";
    let property_search = "";
    let text = "";
    if (this.is_property_dependent) {
      property_search = "&property_type_ids=" + this.selected_property_type;
    }
    if (this.search_input) {
      text = "&attribute_name=" + this.search_input;
    }
    seachString = property_search + text;
    if (this.sortField === field) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = field;
      this.sortOrder = 'asc';
    }
    this.getAttributes(this.sortField, 10000, 0, this.sortOrder, seachString);
  }

  getAttributes(
    sort_by: string,
    limit: number,
    offset: number,
    sort_order: string = 'asc',
    search: string = "",
  ) {
    this.selected_attr_list = [];
    this.showCopy = false;
    if (!this.attributes_access.GET) {
      this.dataSource = [];
      return;
    }
    this.dataSource = [];
    this.group_attribute = [];
    let url =
      this.selected_entity_type +
      "/attributes?sort_by=" +
      sort_by +
      "&sort_order="+
      sort_order +
      "&limit=" +
      limit +
      "&offset=" +
      offset;
      if(this.search_input){
        url += "&attribute_name=" + this.search_input;
      }
      if (this.is_property_dependent) {
        url += "&property_type_ids=" + this.selected_property_type;
      }
    this.loader = true;
    let ctr = 0;
    this.api.getEmsData(url).subscribe({
      next: (data: any) => {
        this.attributes_list = data;
        data.forEach((element) => {
          ctr++;
          let val = {
            sno: ctr,
            name: element.attribute_name,
            property_type: "None",
            data_type: element.data_type,
            is_unique: element.is_unique,
            frequent_search: element.is_frequently_searched,
            is_system_attribute: element.is_system_attribute,
            is_language: element.is_lang_dependent,
            field_length: element.field_length,
            mutually_exclusive:
              typeof element.mutually_exclusive_attributes == "string"
                ? [element.mutually_exclusive_attributes]
                : element.mutually_exclusive_attributes,
            associated_master: "None",
            id: element.attribute_id,
            parent_id: element.parent_attribute_id,
            parent_name: "",
            dependent: element.dependent_attributes,
            property_id: element.property_type_id,
            measure_unit: element.measure_unit_code,
            measure_type: element.measure_unit_type,
            status: element.status,
            default_value: element.default_value,
            associated_master_id: element.master_id,
            description:element.description,
          };
          if (element.parent_attribute_id) {
            let parent = this.fnc.getArrayValue(
              "attribute_id",
              element.parent_attribute_id,
              this.attributes_list
            );
            val.parent_name = parent.attribute_name;
          }
          if (element.property_type_id) {
            let property = this.fnc.getArrayValue(
              "property_type_id",
              element.property_type_id,
              this.api.property_type_list
            );
            val.property_type =
              property &&
              property.property_type_label &&
              property.property_type_label.hasOwnProperty(this.api.language)
                ? property.property_type_label[this.api.language]
                : property.property_type_name;
          }
          if (element.master_id && this.master_list) {
            let master = this.fnc.getArrayValue(
              "master_id",
              element.master_id,
              this.master_list
            );
            val.associated_master =
              master.master_label &&
              master.master_label.hasOwnProperty(this.api.language)
                ? master.master_label[this.api.language]
                : "None";
          }
          this.dataSource.push(val);
        });
        this.createattributesGroup();
        this.loader = false;
      },
      error: (err) => {
        this.loader = false;
        this.dataSource = [];
        this.group_attribute = [];
        this.customize_attribute = [];
      },
    });
  }
  createattributesGroup() {
    let list = this.dataSource;
    let val: any;
    let ctr = 0;
    this.dataSource.forEach((v, k) => {
      ctr++;
      val = {
        sno: ctr,
        id: v.id,
        name: v.name,
        label: v.label,
        data_type: v.data_type,
        measure_type: v.measure_type,
        measure_unit: v.measure_unit,
        property_type: v.property_type,
        default_value: v.default_value,
        is_mandatory: v.is_mandatory,
        is_hidden: v.is_hidden,
        is_editable: v.is_editable,
        is_lang_dependent: v.is_language,
        is_system_attribute: v.is_system_attribute,
        is_unique: v.is_unique,
        is_frequent_search: v.frequent_search,
        status: v.status,
        associated_master: v.associated_master,
        master_id: v.associated_master_id,
        property_type_id: v.property_id,
        field_length: v.field_length,
        mutually_exclusive: v.mutually_exclusive,
        parent_id: v.parent_id,
        dependent: [],
        mutual: [],
        child: [],
        description:v.description,
      };
      if (!v.label) {
        val.label = v.name;
      }
      //this.group_attribute.push(val);
      if (v.mutually_exclusive) {
        v.mutually_exclusive.forEach((mtl) => {
          this.used_attribute.push(mtl);
          let mtl_val = this.fnc.getArrayValue("id", mtl, this.dataSource);
          if (mtl_val) {
            ctr++;
            let mtl_obj = {
              sno: ctr,
              id: mtl_val.id,
              name: mtl_val.name,
              label: mtl_val.label,
              data_type: mtl_val.data_type,
              measure_type: mtl_val.measure_type,
              measure_unit: mtl_val.measure_unit,
              property_type: mtl_val.property_type,
              default_value: mtl_val.default_value,
              is_mandatory: mtl_val.is_mandatory,
              is_lang_dependent: mtl_val.is_language,
              is_unique: mtl_val.is_unique,
              is_frequent_search: mtl_val.frequent_search,
              is_system_attribute: mtl.is_system_attribute,
              associated_master: mtl_val.associated_master,
              is_hidden: mtl_val.is_hidden,
              is_editable: mtl_val.is_editable,
              master_id: mtl_val.master_id,
              property_type_id: mtl_val.property_id,
              field_length: mtl_val.field_length,
              mutually_exclusive: mtl_val.mutually_exclusive,
              parent_id: mtl_val.parent_id,
              status: mtl_val.status,
              description:mtl_val.description,
            };
            if (!mtl_val.label) {
              mtl_obj.label = mtl_val.name;
            }
            val.mutual.push(mtl_obj);
            // this.group_attribute.push(mtl_obj);
          }
        });
      }
      if (v.parent_id) {
        this.used_attribute.push(v.id);
      }
      list.forEach((prt) => {
        if (prt.parent_id === v.id) {
          ctr++;
          let prt_obj = {
            sno: ctr,
            id: prt.id,
            name: prt.name,
            label: prt.label,
            data_type: prt.data_type,
            measure_unit: prt.measure_unit,
            measure_type: prt.measure_type,
            property_type: prt.property_type,
            associated_master: prt.associated_master,
            default_value: prt.default_value,
            is_lang_dependent: prt.is_language,
            is_unique: prt.is_unique,
            is_frequent_search: prt.frequent_search,
            is_mandatory: prt.is_mandatory,
            is_hidden: prt.is_hidden,
            is_editable: prt.is_editable,
            is_system_attribute: prt.is_system_attribute,
            master_id: prt.master_id,
            property_type_id: prt.property_id,
            field_length: prt.field_length,
            mutually_exclusive: prt.mutually_exclusive,
            parent_id: prt.parent_id,
            status: prt.status,
            description:prt.description,
          };
          if (!prt_obj.label) {
            prt_obj.label = prt.name;
          }
          val.child.push(prt_obj);
          //this.group_attribute.push(prt_obj);
        }
      });
      this.group_attribute.push(val);
    });
    this.used_attribute.forEach((v, k) => {
      // this.group_attribute = this.fnc.getDeletedArray('id', v, this.group_attribute); // remove duplicate list
    });
    this.createAttrGroup();
  }
  createAttrGroup() {
    this.customize_attribute = [];
    let list = this.dataSource;

    this.dataSource.forEach((v) => {
      let val = {
        id: v.id,
        name: v.name,
        label: v.label || v.name,
        data_type: v.data_type,
        measure_type: v.measure_type,
        measure_unit: v.measure_unit,
        default_value: v.default_value,
        is_mandatory: v.is_mandatory,
        is_hidden: v.is_hidden,
        is_editable: v.is_editable,
        is_system_attribute: v.is_system_attribute,
        property_type: v.property_type,
        associated_master: v.associated_master,
        is_lang_dependent: v.is_language,
        is_unique: v.is_unique,
        is_frequent_search: v.frequent_search,
        status: v.status,
        master_id: v.associated_master_id,
        property_type_id: v.property_id,
        field_length: v.field_length,
        mutually_exclusive: v.mutually_exclusive,
        parent_id: v.parent_id,
        dependent: [],
        mutual: [],
        child: [],
        description: v.description,
      };

      // Process mutually exclusive attributes
      const processedMutuals = new Set();
      if (v.mutually_exclusive) {
        v.mutually_exclusive.forEach((mtl) => {
          if (processedMutuals.has(mtl)) {
            // console.warn(`Duplicate mutually exclusive ID skipped: ${mtl}`);
            return;
          }

          let mtl_val = this.fnc.getArrayValue("id", mtl, this.group_attribute);
          // console.log(`Processing mutual ID ${mtl}, value:`, mtl_val);

          if (mtl_val && Object.keys(mtl_val).length > 0) {
            val.mutual.push(mtl_val);
            processedMutuals.add(mtl);
          } else {
            // console.warn(`Failed or invalid mutual value for ID: ${mtl}`);
          }
        });
      }

      // console.log(`Final mutual array for ID ${v.id}:`, val.mutual);

      // Process child attributes
      list.forEach((prt) => {
        if (prt.parent_id === v.id) {
          let prt_obj = this.fnc.getArrayValue("id", prt.id, this.group_attribute);
          if (prt_obj) {
            val.child.push(prt_obj);
          }
        }
      });

      this.customize_attribute.push(val);
      this.load = false;
    });

    // Remove duplicate entries for used attributes
    this.used_attribute.forEach((v) => {
      this.customize_attribute = this.fnc.getDeletedArray("id", v, this.customize_attribute);
    });

    this.all_customize_attribute = this.customize_attribute;
    this.total_records["total"] = this.group_attribute.length;
    this.total_records["parent"] = this.customize_attribute.length;
    this.getTotalRecords();
  }




  getTotalRecords() {
    let childs: number = 0;
    let mutuals: number = 0;
    this.customize_attribute.forEach((prt) => {
      if (prt.child && prt.child.length) {
        childs += prt.child.length;
        prt.child.map((e) => {});
      }
      if (prt.mutual && prt.mutual.length) {
        mutuals += prt.mutual.length;
      }
    });
    this.total_records["child"] = childs;
    this.total_records["mutual"] = mutuals;
  }
  // editPropertyType(v){
  //   console.log(v.id);
  //   this.isproType = v.id;
  //   this.isAssoMast = null;
  // }
  editAssociatedMaster(v) {
    this.temp = v.associated_master;
    this.isAssoMast = v.id;
    this.associated_masterId = v.master_id;   
    this.isproType = null;
  }
  getMasterDropDown(parent) {
    let masters = this.master_list;
    if (parent) {
      let parent_detail = this.fnc.getArrayValue(
        "attribute_id",
        parent,
        this.attributes_list
      );
      masters = this.fnc.getChildArray(
        "parent_master_id",
        parent_detail.master_id,
        this.master_list
      );
    }
    return masters;
  }
  close() {
    this.isproType = null;
    this.isAssoMast = null;
    this.associated_masterId = null;
  }
  isValidate(val: string) {
    if (val == "multi-select" || val == "single-select") {
    } else if (val == "table" || val == "table-row") {
      this.desable = false;
      return this.desable;
    } else {
      this.isAssoMast = null;
      this.desable = true;
      return this.desable;
    }
  }

  updateAttribute(field: string, id: number, event, select_id: number = null) {
    this.load = true;
    let value: any;
    let record = {
      updated_by: this.api.user_id,
    };
    if (select_id != null && select_id != undefined) {
      value = select_id;
    } else if (event.checked) {
      value = true;
    } else {
      value = false;
    }

    record[field] = value;
    //console.log(record);
    this.api
      .patchEmsData(this.selected_entity_type + "/attributes/" + id, record)
      .subscribe({
        next: (data: any) => {
          this.notify.notify("Row updated", "success");
          this.searchAttribute(false);
          this.isAssoMast = null;
        },
        error: (err) => {
          this.load = false;
          this.notify.notify(this.fnc.getErrorMessage(err), "error");
        },
      });
  }

  getMeasureUnitType() {
    let url = "attribute/measureunittypes";
    this.api.getEmsData(url).subscribe({
      next: (data: any) => {
        this.measure_unit_type = data;
      },
      error: (err) => {},
    });
  }

  getDataType() {
    let url = "attribute/datatypes";
    this.api.getEmsData(url).subscribe({
      next: (data: any) => {
        this.data_type = data;
      },
      error: (err) => {},
    });
  }

  getMasters() {
    let url = "masters?sort_by=name&limit=1000&offset=0";
    this.api.getEmsData(url).subscribe({
      next: (data: any) => {
        this.master_list = data;
      },
      error: (err) => {},
    });
  }

  getAllowResource() {
    this.attributes_access = this.fnc.checkResourceAccess("attributes", false);
    this.property_access = this.fnc.checkResourceAccess("propertytypes", false);
    this.entity_access = this.fnc.checkResourceAccess("entities", false);
    if (!this.property_access.GET) {
      this.api.property_type_list = [];
    }
    if (!this.entity_access.GET) {
      this.api.entity_type_list = [];
    }
  }

  openDialog(type: string, info: any = {}): void {
    let dialogview;
    info.pObj = this;
    if (type == "createattribute") {
      dialogview = CreateAttributeDialog;
    }
    if (dialogview) {
      let dialogRef = this.dialog.open(dialogview, {
        width: "430px",
        panelClass: "create-master-panel",
        data: info,
      });
      dialogRef.afterClosed().subscribe((result) => {});
    }
  }

  swipeleftactions(s, k) {
    let el = $(this._el.nativeElement);
    el.find("#les" + k)
      .find(".swipeactions")
      .show();
    el.find(".les")
      .not("#les" + k)
      .css("margin-left", "0");
    el.find("#les" + k)
      .show()
      .css("margin-left", "-80px");
  }

  showMobileSearchBar() {
    this.showmobilesearchbar = !this.showmobilesearchbar;
  }

  swiperightactions(s, k) {
    let el = $(this._el.nativeElement);
    el.find(".swipeactions").hide();
    el.find("#les" + k).css("margin-left", "0");
  }

  showSearch() {
    this.showsearchbar = !this.showsearchbar;
  }

  ngOnDestroy() {
    this._crumbs.clear();
  }
}

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_entity_attributes_copy_post</li>
 * </ol>
 *
 */
