import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { DatePipe } from "@angular/common";
import { CommonfunctionService } from "../../../../../../src/app/services/commonfunction.service";

@Component({
  selector: "app-admin-data-table",
  templateUrl: "./admin-data-table.component.html",
  styleUrls: ["./admin-data-table.component.scss"],
  providers: [DatePipe], // Add DatePipe as a provider
})
export class AdminDataTableComponent {
  @Input() data: any[] = [];
  @Input() columns: string[] = [];
  @Input() buttons: any = {}; // Input for buttons configuration
  @Input() totalCount: number;
  @Input() statusMap: { [key: string]: { label: string; color: string } };
  @Input() isNotShowing: boolean;
  @Input() isIcon:boolean;
  @Output() pageChangeAction = new EventEmitter<any>();
  @Output() selectedRowsChange = new EventEmitter<any[]>(); // Output for selected rows
  isAllSelectedChecked: boolean = false;
  selectedRows: any[] = []; // Track selected rows
  pageSizeOptions: number[] = [10, 25, 50, 100];
  defaultPageSize: number = 10;
  pageIndex = 0;
  limit: number = 10;
  offset: number = 0;

  constructor(
    private datePipe: DatePipe,
    private fnc: CommonfunctionService,
    private cdr: ChangeDetectorRef
  ) {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      console.log("Data updated:", changes.data.currentValue);
    }
    if(this.selectedRows?.length > 0 ){
      this.resetCheckboxState();
    }
  }
  ngOnInit(): void {}

  // Function to handle disabled state
  // isButtonDisabled(button: any): boolean {
  //   // Your logic to determine if the button should be disabled
  //   return false; // Replace with your condition
  // }

  /**
   * Checks if a value is in a valid date format using regex.
   */
  isDate(value: any): boolean {
    // Regex to match date formats like "YYYY-MM-DD HH:MM:SS" or similar
    const dateRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}(\.\d+)?$/;
    return typeof value === "string" && dateRegex.test(value);
  }

  /**
   * Formats the value for display.
   * If the value is a date, it formats it to 'd MMM yyyy HH:mm:ss'.
   * Limits to 25 characters and adds ellipsis if needed.
   * Replaces null, undefined, or empty values with a dash.
   */
  formatDisplayValue(value: any): string {
    if (value === null || value === undefined || value === "") {
      return "--";
    }
    // Check if the value is in the specific format 'YYYY-MM-DDTHH:mm:ss.ssssss'
    if (this.isSpecificDateFormat(value)) {
      return this.fnc.formatDateUTC(value, true);
    }
    return value.toString().length > 20
      ? value.toString().slice(0, 20) + "..."
      : value.toString();
  }
  isSpecificDateFormat(value: string): boolean {
    // Regex to match the standard ISO 8601 format (up to 6 digits after the decimal point)
    const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d+$/;

    // Check if the value matches the format with any number of digits after the decimal
    if (regex.test(value)) {
      // If the date is valid, just return true without worrying about the precision
      return true;
    }

    return false;
  }


  /**
   * Formats the value for the tooltip.
   * Replaces null, undefined, or empty values with a dash.
   */
  formatValue(value: any): string {
    return value === null || value === undefined || value === ""
      ? "--"
      : value.toString();
  }

  /**
   * Checks if a tooltip is needed based on the length of the value.
   */
  isTooltipNeeded(value: any): boolean {
    return value && value.toString().length > 20;
  }

  pageAction(e: PageEvent) {
    this.pageIndex = e.pageIndex;
    if (this.limit !== e.pageSize) {
      this.limit = e.pageSize;
      this.offset = 0;
    } else {
      this.offset = this.pageIndex * e.pageSize;
    }
    const pagedetails = {
      limit: this.limit,
      offset: this.offset,
    };
    this.pageChangeAction.emit(pagedetails);
  }
  isButtonDisabled(element: any, button: any) {
    // // Example condition based on element status and button label
    // if (button.label === 'Accept') {
    //   return element.status !== '1';  // Disable Accept if status is not 'Valid'
    // } else if (button.label === 'Reject') {
    //   return element.status !== '1';  // Disable Reject if status is not 'Valid'
    // }
    // // Add other conditions as needed
    // return false;  // Default: button is enabled
  }

  // Function to handle "select all" checkbox in header
 // Function to handle "select all" checkbox in header
 toggleAllRows(event: any): void {
  if (event.checked) {
    // Select all rows in the current data
    this.selectedRows = [...this.data];
  } else {
    // Clear all selections
    this.selectedRows = [];
  }
  this.selectedRowsChange.emit(this.selectedRows);
}

// Function to handle individual row checkbox
toggleRow(element: any): void {
  const index = this.selectedRows.indexOf(element);
  if (index === -1) {
    this.selectedRows.push(element);
  } else {
    this.selectedRows.splice(index, 1);
  }
  this.selectedRowsChange.emit(this.selectedRows);
}

// Function to delete selected rows
deleteSelectedRows(): void {
  // Remove selected rows from data
  this.data = this.data.filter(item => !this.selectedRows.includes(item));

  // Reset selected rows (header checkbox state will be recalculated)
  this.selectedRows = [];
  this.selectedRowsChange.emit(this.selectedRows); // Notify changes

  // Recalculate the "select all" checkbox state
  this.updateSelectAllCheckboxState();
}

// Function to check if all rows are selected
isAllSelected(): boolean {
  return (
    this.data.length > 0 && this.selectedRows.length === this.data.length
  );
}

// Function to check if some rows are selected
isSomeSelected(): boolean {
  return (
    this.data.length > 0 &&
    this.selectedRows.length > 0 &&
    this.selectedRows.length < this.data.length
  );
}

// Function to update the "select all" checkbox state after deletion
updateSelectAllCheckboxState(): void {
  if (this.selectedRows.length === 0 || this.selectedRows.length < this.data.length) {
    this.isAllSelectedChecked = false; // Uncheck "select all"
  }
  if (this.selectedRows.length === this.data.length) {
    this.isAllSelectedChecked = true; // Check "select all" when all rows are selected
  }
}

// Function to check if a specific row is selected
isRowSelected(element: any): boolean {
  return this.selectedRows.indexOf(element) !== -1;
}

// Function to reset the checkbox state
resetCheckboxState(): void {
  // Uncheck the "Select All" checkbox by clearing selectedRows
  this.selectedRows = [];
  this.selectedRowsChange.emit(this.selectedRows); // Notify changes

  this.cdr.detectChanges(); // Ensure UI updates
}
}
