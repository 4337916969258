import {
  Component,
  OnInit,
  HostListener,
  AfterViewInit,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { AdminMenusService } from "../admin-sidebar/admin-menus.service";
import { AdminBreadcrumbService } from "../admin-breadcrumb/services/admin-breadcrumb.service";
import { Options } from "sortablejs";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent, MatPaginator } from "@angular/material/paginator";
import * as $ from "jquery";
import { ApiserviceService } from "../../apiservice.service";
import { NotificationService } from "../services/notification.service";
import { CommonfunctionService } from "../../services/commonfunction.service";
import { Router } from "@angular/router";
import { ShareService } from "../services/share.service";
import { debounceTime } from "rxjs";
import { CreateFieldDialog } from "./components/create-update-field/admin-createfields.component";
import { ShuffleConfirmationDialog } from "./components/Shuffle-Confirmation-Dialog/shuffle-confirmation-dialog.component";
import { FieldShuffleDialog } from "./components/field-shuffle-dialog/field-suffle-dialog.component";
import { ChangeLanguageDialog } from "./components/language-change/change-language-dialog.component";
import { ChangeDescriptionLanguageDialog } from "./components/changed-scription-language/changede-scription-language.component";
import { GroupFieldsList } from "./components/admin-group-list/admin-grouplist.component";
import { FieldSubmasterContextMenu } from "./components/field-submaster-contextmenu/field-submaster-contextmenu.component";
import { FieldMasterContextMenu } from "./components/field-master-contextmenu/field-master-contextmenu.component";

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_module_type_entity_attributes_patch</li>
 * <li>umsapi_user_resource_post</li>
 * <li>estapi_entities_get</li>
 * <li>estapi_propertytypes_get</li>
 * <li>estapi_module_type_entity_entities_attributes_get</li>
 * <li>estapi_module_type_entity_attributegroups_get</li>
 * <li>estapi_module_type_entity_entities_attributes_attribute_id_patch</li>
 * <li>estapi_moduletypes_get</li>
 * <li>estapi_assignments_get</li>
 * </ol>
 *
 */

@Component({
  selector: "app-admin-field",
  templateUrl: "./admin-field.component.html",
  styleUrls: ["./admin-field.component.scss"],
  entryComponents: [
    GroupFieldsList,
    FieldSubmasterContextMenu,
    FieldMasterContextMenu,
    ChangeLanguageDialog,
  ],
})
export class AdminFieldComponent implements OnInit, AfterViewInit {
  @ViewChild(GroupFieldsList, { static: true }) groupclass: GroupFieldsList;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  total: number;
  pageSizeOptions: number[] = [5, 10, 15, 25, 50, 100];
  defaultPageSize: number = 10;
  pageEvent: PageEvent;
  pageIndex = 0;
  searchAssignment = "";
  searchType = "";
  pageNo: FormControl;
  limit: number = 10;
  offset: number = 0;
  undefined_total_records: number;
  pageAction(e) {
    this.defaultPageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.pageNo.setValue(e.pageIndex + 1);
    this.limit = e.pageSize;
    this.offset = this.pageIndex * e.pageSize;
    this.getFields("sort_order", this.limit, this.offset);
  }
  resetpagination() {
    this.defaultPageSize = 10;
    if (this.paginator) this.paginator.firstPage();
  }
  displayedColumns = [
    "checkboxes",
    "sno",
    "name",
    "language",
    "description_language",
    "status",
    "show_hide",
    "mandatory",
    "listable",
    "editable",
  ];
  tableoptions: Options = {
    draggable: ".draggableset",
    onUpdate: (event: any) => this.sortFields(),
  };
  attributegroups_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };
  attributes_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };
  assignment_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };
  module_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };
  property_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };
  entity_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };
  searchField: FormControl;
  mobilemasterpopup = false;
  mobilemasterpopupslideout = false;
  selected_property_type: number;
  selected_entity_type: string;
  search_input: string;
  selected_assignment: number;
  selected_module: string;
  selected_group: any;
  loader: boolean;
  undefined_length: number;
  is_property_dependent: boolean;
  is_assignment_dependent: boolean;
  selected_attributes_id: Array<number> = [];
  module_list: Array<any>;
  group_list = [];
  total_groups: number = 0;
  field_list: Array<any> = [];
  group_custom_list: any;
  assignment_list: Array<any>;
  groupdata: {} = {};
  languageeditdata: {} = {};
  showsearchbar: boolean = false;
  showmobilesearchbar: boolean = false;
  ready_load: boolean = false;
  field_group: any;
  dependent_list: any;
  confirm: boolean;
  // height: number;
  // inHeight: number;
  callFrom: string = "adminfield";
  showRemove: boolean = false;

  constructor(
    public dialog: MatDialog,
    private menus: AdminMenusService,
    private _crumbs: AdminBreadcrumbService,
    private _el: ElementRef,
    public notify: NotificationService,
    private fnc: CommonfunctionService,
    public api: ApiserviceService,
    private route: Router,
    private share: ShareService,
    private cdr: ChangeDetectorRef
  ) {
    this.pageNo = new FormControl();
    this.share.fieldObj = this;
    this.menus.setActive("field_settings");
    this._crumbs.clear();
    this._crumbs.addcrumb = { title: "Attribute Settings" };
    this._crumbs.addcrumb = { title: "Group" };
    this._crumbs.mobiletitle = "Attribute Settings";
    this._crumbs.count = 1;
    this.onResize();
    this.getAllowResourcesApi();
    this.getAssignment();
  }
  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    // this.height = (window.innerHeight - 220);
    // this.inHeight = (window.innerHeight - 288);
  }
  openConfirmationDialog() {
    let confimDialog = this.dialog.open(ShuffleConfirmationDialog, {
      width: "300px",
      panelClass: "create-master-panel",
      data: { parent: this },
    });
    confimDialog.afterClosed().subscribe((result) => {});
  }

  getAttributUndefine() {
    this.resetpagination();
    this.resetPaginat()
    this.getFields("sort_order", this.limit, 0, "undefined");
    this.getTotalcount("sort_order", null, null, "undefined");

  }
  getTotalcount(
    sort: string,
    limit: number,
    offset: number,
    search: string = "",
    isQuickEdit: boolean = false
  ) {

    this.selected_attributes_id = []; //pointer
    if (isQuickEdit) this.share.quick_edit_count.next(0);
    let searchString = search;
    if (!this.attributes_access.GET) {
      this.field_list = [];
      return;
    }
    if (search == "undefined") {
      this.selected_group = null;
      this.groupclass.selected = null;
      this.groupclass.sub_selected = null;
      this.showRemove = false;
      search = "&group_id=-1"; // for undefined
      this.undefined_length = 0;
    }
    if (search != "" && search != "undefined") {
      // this.selected_group = null;
    }

    if (this.selected_group && this.selected_group != "quick") {
      search += "&group_id=" + this.selected_group?.id;
    }
    if (isQuickEdit) {
      sort = "quick_edit_sort";
      search += "&quick_edit=true"; //new attribute Quick Edit....
    } else {
      if (search == "" && !this.selected_group && !isQuickEdit) {
        // first load for undefined
        this.undefined_length = 0;
        search = "&group_id=-1";
      }
    }

    if (this.is_property_dependent) {
      search += "&property_type_ids=" + this.selected_property_type;
    }

    let url =
      this.selected_module +
      "/" +
      this.selected_entity_type +
      "/attributes?sort_by=" +
      sort +
      "&limit=10000&offset=0&assignment_id=" +
      this.selected_assignment +
      search;

      this.api.getEmsData(url).subscribe({
        next: (res: any) => {
          // Check if the response is valid - card id I518
          if (res) {
            // Update the total count of records
            this.total = res.length;

            // If quick edit is enabled, update the quick edit count
            if (isQuickEdit) {
              this.share.quick_edit_count.next(res.length);
            } else if (this.selected_group == null) {
              // Store the length of undefined records if no group is selected
              this.undefined_length = res.length;

              // If the search value is empty or specifically equal to "&group_id=-1",
              // set undefined_total_records to the total count of records
              if (!search || search.trim() === "" || search === "&group_id=-1") {
                this.undefined_total_records = this.total;
              }
            }
          }
        },
        error: (err) => {
          // In case of an error, reset the total count
          this.total = 0;
        },
      });
  }
  removeFromQuickAction() {
    let url =
      this.selected_module + "/" + this.selected_entity_type + "/attributes";
    let val = {};
    this.field_group = [];
    this.selected_attributes_id.forEach((v) => {
      val = {
        updated_by: this.api.user_id,
        attribute_id: v,
        quick_edit: false,
        move_linked_attributes: this.confirm,
        assignment_id: this.selected_assignment,
      };
      this.field_group.push(val);
    });
    this.api.patchEmsData(url, this.field_group).subscribe({
      next: (data: any) => {
        this.selected_attributes_id = [];
        this.notify.notify(
          "Attributes successfully removed from Quick Edit!",
          "success"
        );
        this.searchAttribute(true, true);
      },
      error: (err) => {
        // this.selected_attributes_id = [];
        //this.notify.notify(this.fnc.getErrorMessage(err), 'error');
        if (err.status == 500 && !this.confirm) {
          let end,
            dependent_string = "";
          let msg = this.fnc.getErrorMessage(err);
          let list = msg.split("{");
          list.forEach((element, k) => {
            if (k != 0) {
              end = element.indexOf("}");
              if (end != -1) {
                dependent_string += element.substring(0, end) + "|";
              }
            }
          });
          this.dependent_list = dependent_string;
          this.openConfirmationDialog();
          this.confirm = false;
        }
      },
      complete: () => {
        this.confirm = false;
        this.selected_attributes_id = [];
      },
    });
  }
  // getting allow resources
  getAllowResourcesApi() {
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`;
    this.api.getUmsData(body).subscribe({
      next: (res: any) => {
        this.api.allowResources = res.data;
        this.getAllowResource();
        this.getModule();
      },
      error: (err) => {},
    });
  }
  // getAllowResourcesApi() {
  //   let body = "place_id=" + this.api.city_id;
  //   this.api.postUmsData('user/resource', body)
  //     .subscribe(
  //       (data:any) => {
  //         this.api.allowResources = data;
  //         this.getAllowResource();
  //         this.getModule();
  //       },
  //       err => {
  //       }
  //     )
  // }

  // getting list of entity type
  getEntityType() {
    this.api.getEmsData("entities").subscribe((data: any) => {
      this.api.entity_type_list = data;
      this.getPropertyType();
    });
  }

  ///selection on Change from top reset count of quick edit and undefiled attribute
  selectionChange(val) {
    this.undefined_length = 0;
    this.share.quick_edit_count.next(0);
    this.resetpagination();
    this.resetPaginat()
    this.searchAttribute(val);
    this.getFields("sort_order", this.limit, this.offset, "", false);
  }
  // getting list of property type
  getPropertyType(sort_by = "property_type_name", limit = 10000, offset = 0) {
    let url =
      "propertytypes?sort_by=" +
      sort_by +
      "&limit=" +
      limit +
      "&offset=" +
      offset +
      "&status=1";
    this.api.getEmsData(url).subscribe((data: any) => {
      this.api.property_type_list = data;
      this.selected_entity_type = this.api.entity_type_list[0].entity_name;
      this.selected_property_type =
        this.api.property_type_list[0].property_type_id;
      if (this.assignment_list)
        this.selected_assignment = this.assignment_list[0].assignment_id;
      this.selected_module = this.module_list[0].module_type_name;
      this.searchAttribute(false);
    });
  }
  searchAttribute(val, quickEdit = false) {
    if (!quickEdit) {
      // this.showRemove = false;
      this.share.gpObj.activeToggle = false;
    } else {
      this.showRemove = true;
    }
    let seachString = "",
      property_search = "",
      text = "";
    let entity_info = this.fnc.getArrayValue(
      "entity_name",
      this.selected_entity_type,
      this.api.entity_type_list
    );
    let module_info = this.fnc.getArrayValue(
      "module_type_name",
      this.selected_module,
      this.module_list
    );
    // if (this.paginator) {  ////To make table's paginator record count back to 10(default)
    //   this.defaultPageSize = 10;
    //   this.paginator.firstPage();
    //   let e = { length: this.paginator.length, pageIndex: 0, pageSize: 10, previousPageIndex: 0 };
    //   this.pageAction(e);
    // }

    this.is_property_dependent = entity_info.is_property_type_dependent;
    this.is_assignment_dependent = module_info.is_assignment_dependent;
    if (this.search_input) {
      text = "&attribute_name=" + this.search_input;
    }
    seachString = property_search + text;
    if (!val) {
      this.resetpagination();
      this.getGroups("sort_order", 1000, 0, seachString);
    } else {
      this.getFields("sort_order", this.limit, 0, seachString, quickEdit);
      this.getTotalcount("sort_order", null, null, seachString, quickEdit);
    }
    // this.getFields('sort_order', 10, 0, seachString, true);
  }

  getFields(
    sort: string,
    limit: number,
    offset: number,
    search: string = "",
    isQuickEdit: boolean = false
  ) {
    this.selected_attributes_id = []; //pointer
    if (isQuickEdit) this.share.quick_edit_count.next(0);
    if (!this.attributes_access.GET) {
      this.field_list = [];
      return;
    }
    if (search == "undefined") {
      this.selected_group = null;
      this.groupclass.selected = null;
      this.groupclass.sub_selected = null;
      this.showRemove = false;
      search = "&group_id=-1"; // for undefined
      this.undefined_length = 0;
    }
    if (this.selected_group && this.selected_group != "quick") {
      search += "&group_id=" + this.selected_group?.id;
    }

    if (isQuickEdit) {
      sort = "quick_edit_sort";
      search += "&quick_edit=true"; //new attribute Quick Edit....
    } else {
      if (search == "" && !this.selected_group && !isQuickEdit) {
        search = "&group_id=-1";
      }
    }

    if (this.is_property_dependent) {
      search += "&property_type_ids=" + this.selected_property_type;
    }

    let language: any;
    let desc = "";
    let label = "";
    this.loader = true;
    let asc = true;
    let url =
      this.selected_module +
      "/" +
      this.selected_entity_type +
      "/attributes?sort_by=" +
      sort +
      "&limit=" +
      limit +
      "&asc=" +
      asc +
      "&offset=" +
      offset +
      "&assignment_id=" +
      this.selected_assignment + search;
    //this.field_list = [];
    this.api.getEmsData(url).subscribe({
      next: (data: any) => {
        if (isQuickEdit) {
          this.share.quick_edit_count.next(data.length);
        }
        this.field_list = [];
        this.loader = false;
        if (data.length) {
          let ctr = 1 + this.offset;
          data.forEach((element) => {
            (desc = ""), (label = "");
            if (element.description) {
              desc = element.description[this.api.language];
            }
            if (element.attribute_label) {
              label =
                element.attribute_label[this.api.language] == undefined
                  ? element.attribute_name
                  : element.attribute_label[this.api.language];
            }
            let val = {
              sno: ctr,
              name: element.attribute_name,
              label: label,
              description: desc,
              label_right: [],
              description_right: [],
              id: element.attribute_id,
              status: element.status,
              property_type_id: element.property_type_id,
              labels: element.attribute_label,
              descriptions: element.description,
              is_hidden: element.is_hidden,
              is_mandatory: element.is_mandatory,
              is_listable: element.is_listable,
              is_editable: element.is_editable,
              group_id: element.group_id,
              mutually_exclusive: element.mutually_exclusive_attributes,
              dependent: element.dependent_attributes,
              parent_id: element.parent_attribute_id,
            };
            language = "";
            if (element.attribute_label) {
              for (let key in element.attribute_label) {
                //getting language detail by id
                language = this.fnc.getArrayValue(
                  "language_id",
                  key,
                  this.api.language_list
                );
                let lang_value = {
                  label:
                    "<span class='menu-icon'>" +
                    language.name +
                    " - " +
                    element.attribute_label[key] +
                    "</span>",
                };
                val.label_right.push(lang_value);
              }
            }
            if (element.description) {
              for (let key in element.description) {
                //getting language detail by id
                language = this.fnc.getArrayValue(
                  "language_id",
                  key,
                  this.api.language_list
                );
                let lang_value = {
                  label:
                    "<span class='menu-icon'>" +
                    language.name +
                    " - " +
                    element.description[key] +
                    "</span>",
                };
                val.description_right.push(lang_value);
              }
            }
            ctr++;
            this.field_list.push(val);
          });
        }
      },
      error: () => {
        this.field_list = [];
        this.loader = false;
      },
    });
  }

  getGroups(sort: string, limit: number, offset: number, search: string = "") {
    if (this.is_property_dependent) {
      search += "&property_type_ids=" + this.selected_property_type;
    }
    let url =
      this.selected_module +
      "/" +
      this.selected_entity_type +
      "/attributegroups?sort_by=" +
      sort +
      "&limit=" +
      limit +
      "&offset=" +
      offset +
      "&assignment_id=" +
      this.selected_assignment +
      search;
    this.group_custom_list = [];
    this.group_list = [];
    this.field_list = [];
    if (!this.attributegroups_access.GET) {
      return;
    }
    this.getTotalcount("sort_order", null, null, "undefined");
    this.getFields("sort_order", this.limit, this.offset, "undefined");
    this.total_groups = 0;
    this.api.getEmsData(url).subscribe((data: any) => {
      this.group_list = data;
      this.total_groups = this.group_list.length;
      data.forEach((element) => {
        if (!element.parent_group_id) {
          let list = {
            group_name:
              element.group_label[this.api.language] == undefined
                ? element.group_name
                : element.group_label[this.api.language],
            childrens: [],
            id: element.group_id,
            name: element.group_name,
            property_id: element.property_type_id,
            labels: element.group_label,
            description: element.description,
            status: element.status,
            parent_id: element.parent_group_id,
            attributes_count: element.attribute_count,
            sub_group_count: 0,
          };
          let group_ctr = 0;
          let attribute_group_count = 0;
          this.group_list.forEach((sub) => {
            if (sub.parent_group_id == element.group_id) {
              let sub_list = {
                group_name:
                  sub.group_label[this.api.language] == undefined
                    ? sub.group_name
                    : sub.group_label[this.api.language],
                id: sub.group_id,
                name: sub.group_name,
                property_id: sub.property_type_id,
                labels: sub.group_label,
                description: sub.description,
                status: sub.status,
                parent_id: sub.parent_group_id,
                attributes_count: sub.attribute_count,
              };
              list.childrens.push(sub_list);
              attribute_group_count += sub.attribute_count;
              list.sub_group_count = ++group_ctr; // counting sub group
            }
          });
          list.attributes_count += attribute_group_count;
          this.group_custom_list.push(list);
        }
      });
    });
  }

  updateFields(field: string, element: any, event) {
    let id = element.id;
    const checkbox = event.source as HTMLInputElement;
    const newStatus = checkbox.checked ? 1 : 0;
    let value = 0;
    let url =
      this.selected_module +
      "/" +
      this.selected_entity_type +
      "/attributes/" +
      id;
    let record = {
      updated_by: this.api.user_id,
      assignment_id: this.selected_assignment,
    };
    if(element.parent_id != null){
      record["parent_attribute_id"]= element.parent_id;
    }
    if (event.checked) value = 1;
    record[field] = value;
    this.api.patchEmsData(url, record).subscribe({
      next: (data: any) => {
      if(data.status == 200){
        this.notify.notify("Row updated", "success");
        this.getFields("sort_order", this.limit, this.offset);
      }
      else if(data.status == 400){
        this.notify.notify(data.message, "warn");
        checkbox.checked = !checkbox.checked;
      }
      },
      error: (err) => {
        this.notify.notify(this.fnc.getErrorMessage(err), "error");
      },
    });
  }

  getAllowResource() {
    // attributes groups
    this.attributegroups_access =
      this.fnc.checkResourceAccess("attributegroups");
    this.attributes_access = this.fnc.checkResourceAccess("attributes");
    this.assignment_access = this.fnc.checkResourceAccess("assignments");
    this.module_access = this.fnc.checkResourceAccess("moduletypes");
    this.property_access = this.fnc.checkResourceAccess("propertytypes");
    this.entity_access = this.fnc.checkResourceAccess("entities");
    if (!this.module_access.GET) {
      this.module_list = [];
    }
    if (!this.assignment_access.GET) {
      this.assignment_list = [];
    }
    if (!this.property_access.GET) {
      this.api.property_type_list = [];
    }
    if (!this.entity_access.GET) {
      this.api.entity_type_list = [];
    }
  }

  getModule() {
    this.api.getEmsData("moduletypes").subscribe({
      next: (data: any) => {
        this.module_list = data;
        this.getEntityType();
      },
      error: (err) => {},
    });
  }

  getAssignment() {
    this.api
      .getEmsData("assignments?sort_by=assignment_name&status=1")
      .subscribe({
        next: (data: any) => {
          //change sort order
          data = this.fnc.assignmentSortOrder(data);
          this.assignment_list = [];
          data.forEach((v) => {
            if (v.assignment_label[this.api.language]) {
              v["label"] = v.assignment_label[this.api.language];
            } else {
              v["label"] = v.assignment_name;
            }
            this.assignment_list.push(v);
          });
        },
        error: (err) => {},
      });
  }

  setAttributeId(e) {
    if (e.checked) {
      this.selected_attributes_id.push(e.source.value);
    } else {
      this.selected_attributes_id.splice(
        this.selected_attributes_id.indexOf(e.source.value),
        1
      );
    }
  }
  selectGroup(item) {
    this.undefined_length = this.undefined_total_records;
    this.selected_group = item;
    this.limit = 10;
    this.offset = 0;
    this.showRemove = false;
    this.selected_attributes_id = []; //pointer
    this.selected_group = item;
    this.resetPaginat()
    this.resetpagination();
    this.getTotalcount("sort_order", null, null, "", false);
    this.getFields("sort_order", this.limit, this.offset, "", false);

  }

  resetPaginat() {
    // if (this.paginator) {  ////To make table's paginator record count back to 10(default)
      this.defaultPageSize = 10;
      this.paginator.firstPage();
      let e = { length: this.paginator.length, pageIndex: 0, pageSize: 10, previousPageIndex: 0 };
      this.pageAction(e);
      this.cdr.detectChanges();
    // }
  }
  sortFields() {
    let fields = [];
    let val = {};

    if (!this.attributes_access.PATCH) {
      this.notify.notify("You are not authorize to update Attribute", "warn");
      return;
    }
    if (this.selected_group == "quick") {
      this.field_list.forEach((v, k) => {
        v.sno = k + 1; // serial number
        val = {
          attribute_id: v.id,
          quick_edit_sort: k,
          updated_by: this.api.user_id,
          assignment_id: this.selected_assignment,
        };
        fields.push(val);
      });
    } else {
      this.field_list.forEach((v, k) => {
        v.sno = k + 1; // serial number
        val = {
          attribute_id: v.id,
          sort_order: k,
          updated_by: this.api.user_id,
          assignment_id: this.selected_assignment,
        };
        fields.push(val);
      });
    }
    let url =
      this.selected_module + "/" + this.selected_entity_type + "/attributes";
    this.api.patchEmsData(url, fields).subscribe({
      next: (data: any) => {
        //this.notify.notify('Groups order updated','Dismiss');
      },
      error: (err) =>
        this.notify.notify(this.fnc.getErrorMessage(err), "error"),
    });
  }

  selectField(val) {
    let grouplist: any;
    let selected_id: number;
    if (val.group_id) {
      let group = this.fnc.getArrayValue(
        "group_id",
        val.group_id,
        this.group_list
      );
      if (group.parent_group_id) {
        selected_id = group.parent_group_id;
        grouplist = this.fnc.getArrayValue(
          "id",
          group.parent_group_id,
          this.group_custom_list
        );
        grouplist = grouplist.childrens;
      } else {
        selected_id = group.group_id;
        grouplist = this.group_custom_list;
      }
      let current_group = this.fnc.getArrayValue("id", val.group_id, grouplist);
      this.selected_group = current_group;
      this.groupclass.select(selected_id);
      this.groupclass.sub_selected = current_group.id;
    } else {
      //this.selected_group
    }
  }

  loadlistevent(event) {
    this.groupdata = event.item;
    this.openDialog(event.form, { val: event.item });
  }

  triggerswipe(data) {
    this.swiped();
  }

  swiped() {
    this.mobilemasterpopupslideout = true;
    setTimeout(
      function () {
        this.mobilemasterpopup = false;
      }.bind(this),
      1000
    );
  }

  saveForm(form) {
    this.notify.notify("Form Saved", "Dismiss");
    //Send to the API
    // this.groups.push(form.value);
  }
  //drag start....
  draggable = {
    data: "myDragData",
    effectAllowed: "all",
    disable: false,
    handle: false,
  };
  onDragStart(e) {}
  onDragCanceled(e) {}
  //drag end....
  ngOnInit() {
    this.searchField = new FormControl();
    this.searchField.valueChanges.pipe(debounceTime(1000)).subscribe({
      next: (term) => {
        this.search_input = term;
        this.searchAttribute(true);
      },
    });
  }

  openDialog(type: string, info: any = {}): void {
    let dialogview;
    let width = "500px";
    info.parent = this;

    if (type == "createattribute") {
      dialogview = CreateFieldDialog;
      width = "650px";
    }
    if (type === "changelanguage") {
      dialogview = ChangeLanguageDialog;
    }
    if (type === "groupshuffledialog") {
      dialogview = FieldShuffleDialog;
      info["class"] = "popupshuffle";
    }
    if (type === "changedescriptionlanguage") {
      dialogview = ChangeDescriptionLanguageDialog;
    }
    if (dialogview) {
      let dialogRef = this.dialog.open(dialogview, {
        width: width,
        panelClass: "create-master-panel",
        data: info,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (type === "groupshuffledialog") {
          this.selected_attributes_id = [];
        }
      });
    }
  }
  isChecked(id) {
    return this.selected_attributes_id.indexOf(id) > -1;
  }
  changelanguage(ele, type) {
    if (type == "description") {
      this.openDialog("changedescriptionlanguage", { val: ele });
    } else {
      this.openDialog("changelanguage", { val: ele });
    }
  }

  showMasterGroup() {
    this.mobilemasterpopup = !this.mobilemasterpopup;
    if (this.mobilemasterpopup) {
      this.mobilemasterpopupslideout = false;
    }
  }

  swipeleftactions(s, k) {
    let el = $(this._el.nativeElement);
    el.find("#les" + k)
      .find(".swipeactions")
      .show();
    el.find(".les")
      .not("#les" + k)
      .css("margin-left", "0");
    el.find("#les" + k)
      .show()
      .css("margin-left", "-80px");
  }

  showMobileSearchBar() {
    this.showmobilesearchbar = !this.showmobilesearchbar;
  }

  swiperightactions(s, k) {
    let el = $(this._el.nativeElement);
    el.find(".swipeactions").hide();
    el.find("#les" + k).css("margin-left", "0");
  }

  showSearch() {
    this.showsearchbar = !this.showsearchbar;
  }

  ngOnDestroy() {
    this._crumbs.clear();
  }
  public hovertablecellindex = -1;
  hoverTableCell(row) {
    this.hovertablecellindex = row;
  }
  hoverOutTableCell() {}

  ngAfterViewInit() {
    var mob = $(window).height() - 167;
    // $(".pack").css('height', mob);
    // $(".grouplist").css('height', $(window).height() - 110)
    // $(".subgrouplist").css('height', $(window).height() - 110)
  }
  isCheckboxChecked(element: any): boolean {
    return element.is_editable || element.is_mandatory;
  }

  isEditableDisabled(element: any): boolean {
    return element.is_mandatory;
  }
}
