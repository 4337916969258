<!-- Admin File Upload UI Component -->
<!-- This section provides a user interface for file upload functionality, where users can select a file or drag & drop it, then submit it for upload. -->
<div class="gridV">

  <!-- Button grid wrapper for file upload -->
  <div class="btnGrid">

    <!-- Upload button wrapper -->
    <div class="upload-btn-wrapper">
      <!-- Inner wrapper for styling the file selection and upload instructions -->
      <div class="wrapper">
        <!-- Button to trigger file selection dialog -->
        <button class="sel">Select file</button>
        <!-- Text prompt for users to drag & drop files -->
        <p> or Drag & drop file here</p>
      </div>

      <!-- File input element for selecting a file to upload -->
      <!-- Accepts only .zip files -->
      <input type="file" #myFileInput name="myfile" (change)="handleFileInput($event)" accept=".zip" />

      <!-- Displays the name of the selected file, with a tooltip for easier access -->
      <div *ngIf="fileName" class="file-url" [matTooltip]="fileName">
        {{fileName}}
      </div>
    </div>

    <!-- Submit button for uploading the selected file -->
    <div class="fileName">
      <!-- The button is disabled while loading (i.e., when the file is being uploaded) -->
      <button class="submit" type="submit" [disabled]="loading" mat-raised-button color="primary" (click)="uploadFile()">
        <!-- SVG icon for the upload button -->
        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="#fff" height="20" viewBox="0 0 24 24" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
          <path d="M0 0h24v24H0z" fill="none"></path>
          <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path>
        </svg>

        <!-- Upload button text changes based on the loading state -->
        <span *ngIf="!loading">Upload</span>
        <span *ngIf="loading">Uploading...</span>
      </button>
    </div>
  </div>
</div>
