import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-no-records-found',
  templateUrl: './no-records-found.component.html',
  styleUrls: ['./no-records-found.component.scss']
})
export class NoRecordsFoundComponent {
@Input() dataNotFoundMessage:string;
@Input() isNotFoundIconShow:boolean;
}
