<div class="chips-wrapper">
    <mat-chip *ngFor="let chip of chipsLabel; let i = index"
        [removable]="true"
        (removed)="removeChip(i)"
        class="custom-chip">
        {{ chip }}
        <button matChipRemove>
            <mat-icon>close</mat-icon>
        </button>
    </mat-chip>
</div>
