import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-admin-selection-dropdown',
  templateUrl: './admin-selection-dropdown.component.html',
  styleUrls: ['./admin-selection-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AdminSelectionDropdownComponent),
      multi: true
    }
  ]
})
export class AdminSelectionDropdownComponent implements ControlValueAccessor{
  @Input() label: string = 'Select';  // Label for the mat-select
  @Input() options: { value: any, label: string }[] = [];  // Options for dropdown
  @Input() multiple: boolean = false;  // Enable multiple selection
  @Output() selectionChangecustom = new EventEmitter<any>(); // Event to emit data
  value: any;  // Internal value
  onChange: (value: any) => void = () => {};  // Callback for value changes
  onTouched: () => void = () => {};  // Callback for touched state
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.options && this.options.length > 0) {
      this.value = this.options[0].value;  // Set the first option as default
      this.onChange(this.value);  // Notify Angular forms
      this.onTouched();
      this.selectionChangecustom.emit(this.value);  // Emit the value to the parent
    }

  }

  // ControlValueAccessor implementation
  writeValue(value: any): void {
    this.value = value;  // Write value from the form control to the internal value
    this.cdr.detectChanges(); 
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;  // Register the change callback
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;  // Register the touched callback
  }

  setDisabledState?(isDisabled: boolean): void {
    // Logic to disable the select if needed
  }

  onSelectionChange(event: any) {
    console.log(event)
    this.value = event.value;  // Update the internal value
    this.onChange(this.value);  // Notify Angular forms about the change
    this.onTouched();  // Mark the control as touched
    this.selectionChangecustom.emit(event.value);  // Emit the selection to the parent component
    this.cdr.detectChanges(); 
  }

}
