<div class="no-data">
  <div *ngIf="isNotFoundIconShow"><svg xmlns="http://www.w3.org/2000/svg" width="62.889" height="49.4" viewBox="0 0 62.889 49.4">
    <g id="svgviewer-output_14_" data-name="svgviewer-output (14)" transform="translate(0 -55)">
      <path id="Path_457" data-name="Path 457" d="M2.212,103.663a1.2,1.2,0,0,1-1.106-.492A3.812,3.812,0,0,1,0,100.468V59.055A4.013,4.013,0,0,1,4.055,55H23.348c1.966,0,3.809,2.458,6.513,7.619H51.612a3.882,3.882,0,0,1,3.932,3.932V69.5a1.627,1.627,0,0,1-1.6,1.6l-50.629,32.2A1.982,1.982,0,0,1,2.212,103.663Z" transform="translate(0 0)" fill="#ffd4b9" fill-rule="evenodd"/>
      <path id="Path_458" data-name="Path 458" d="M54.694,196.5H6.523a3.174,3.174,0,0,1-2.581-1.106,3.379,3.379,0,0,1-.614-2.826L10.7,161.229A1.46,1.46,0,0,1,12.176,160H62.559a3.265,3.265,0,0,1,3.072,4.055L58.75,193.3A4.206,4.206,0,0,1,54.694,196.5Z" transform="translate(-2.836 -92.097)" fill="#e06c24" fill-rule="evenodd"/>
      <g id="Group_5434" data-name="Group 5434" transform="translate(28.264 74.416)">
        <path id="Path_459" data-name="Path 459" d="M233.441,229.1A3.411,3.411,0,0,1,230,225.78v-9.339A3.435,3.435,0,0,1,233.441,213a3.333,3.333,0,0,1,3.318,3.441v9.339A3.3,3.3,0,0,1,233.441,229.1Zm0,7.742A3.517,3.517,0,0,1,230,233.4a3.411,3.411,0,0,1,3.441-3.318,3.3,3.3,0,0,1,3.318,3.318A3.411,3.411,0,0,1,233.441,236.84Z" transform="translate(-230 -213)" fill="#fff" fill-rule="evenodd"/>
      </g>
    </g>
  </svg>
  </div>
  <p>{{dataNotFoundMessage}}</p>
</div>
