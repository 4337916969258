import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDateRangePicker } from '@angular/material/datepicker';
import moment from 'moment';

@Component({
  selector: 'app-admin-date-range-picker',
  templateUrl: './admin-date-range-picker.component.html',
  styleUrls: ['./admin-date-range-picker.component.scss']
})
export class AdminDateRangePickerComponent implements OnInit {
  @Input() placeholderStart: string = 'Start Date';
  @Input() placeholderEnd: string = 'End Date';
  @Output() dateRangeSelected = new EventEmitter<[string, string]>();

  dateRangeForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.dateRangeForm = this.fb.group({
      start: [null],
      end: [null],
    });
  }

  ngOnInit(): void {
    this.dateRangeForm.valueChanges.subscribe((value) => {
      if (value.start && value.end) {
        // Format dates for storage as [yyyy/MM/dd, yyyy/MM/dd]
        const formattedDates: [string, string] = [
          moment(value.start).format('YYYY/MM/DD'),
          moment(value.end).format('YYYY/MM/DD'),
        ];
        this.dateRangeSelected.emit(formattedDates);
      }
    });
  }
  openPicker(picker: MatDateRangePicker<Date>) {
    picker.open();
  }
}
