import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-admin-input-field',
  templateUrl: './admin-input-field.component.html',
  styleUrls: ['./admin-input-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AdminInputFieldComponent),
      multi: true
    }
  ]
})
export class AdminInputFieldComponent implements ControlValueAccessor{
  @Input() label: string = '';                // Label for the input
  @Input() type: string = 'text';             // Type of the input
  @Input() placeholder: string = '';          // Placeholder for the input
  @Input() required: boolean = false;         // Whether the input is required
  @Input() errorMessage: string = 'Invalid input'; // Error message for validation
  @Input() commaSeparatedOnly: boolean = false;    // Flag to enforce comma-separated values

  value: string = '';                         // Internal value
  invalidInput: boolean = false;              // Flag to track invalid input

  // Callbacks for ControlValueAccessor
  onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};

  // Function to update the value when input changes
  updateValue(value: string) {
    if (this.commaSeparatedOnly) {
      // Regular expression to allow only alphanumeric characters and commas
      const isValid = /^[a-zA-Z0-9,]*$/.test(value);

      if (!isValid) {
        this.invalidInput = true;  // Set flag if invalid
      } else {
        this.invalidInput = false;  // Reset flag if valid
        this.value = value;
        this.onChange(value);  // Notify Angular forms about the change
        this.onTouched();      // Mark the input as touched
      }
    } else {
      this.value = value;
      this.onChange(value);    // Notify Angular forms about the change
      this.onTouched();        // Mark the input as touched
    }
  }

  // ControlValueAccessor: Write a value to the input
  writeValue(value: string): void {
    this.value = value || '';  // Initialize or reset the value
  }

  // ControlValueAccessor: Register the change function for Angular Forms
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // ControlValueAccessor: Register the touched function for Angular Forms
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Optional: Disable the input (if required)
  setDisabledState?(isDisabled: boolean): void {
    // Add logic here if you want to disable the input
  }
}
