<div style="position: relative;">
  <mat-toolbar *ngIf="!data.val" class="matpopup-header">
    <div>Create Group / Sub Group</div>
  </mat-toolbar>
  <mat-toolbar *ngIf="data.val" class="matpopup-header">
    <div>Edit Group / Sub Group</div>
  </mat-toolbar>
  <!--<p>Validation status: {{ groupform.status }}</p>-->
  <form [formGroup]="groupform" (ngSubmit)="saveform(groupform)">
    <mat-dialog-content>
      <div class="form-container">
        <div class="form-input-container" fxLayout="row" fxLayoutAlign="space-between center">
          <label fxFlex="30%">Group Name</label>
          <mat-form-field floatLabel="never" fxFlex="70%">
            <input type="text" [errorStateMatcher]="matcher" #group_name maxlength="64" formControlName="group_name"
              name="group_name" matInput />
            <mat-error *ngIf="groupName.hasError('pattern')">
              Special characters are not allowed
            </mat-error>
            <mat-error *ngIf="groupName.hasError('required')"> Group Name is required</mat-error>
            <mat-hint align="end"> {{group_name.value.length}}/64 </mat-hint>
          </mat-form-field>
        </div>

        <div class="form-input-container" fxLayout="row" fxLayoutAlign="space-between start">
          <label fxFlex="30%" style="padding-top: 2%;">Label</label>
          <div fxFlex="70%" fxLayout="column" formArrayName="language">
            <div *ngFor="let languagevalues of language_valueset;let kk = index">
              <div [formGroupName]="kk" fxLayout="row" class="gap-10" fxLayoutAlign="space-between center">
                <mat-form-field floatLabel="never">
                  <mat-select formControlName="language" name="language">
                    <mat-option *ngFor="let language of languages" [value]="language.language_id">
                      {{ language.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field floatLabel="never">
                  <input type="text" [errorStateMatcher]="matcher" matInput #label maxlength="64"
                    formControlName="value" name="value" />
                  <mat-error *ngIf="!groupform.controls.language.controls[kk].controls.value.valid">
                    Label is required
                  </mat-error>
                  <mat-hint align="end"> {{label.value.length}}/64 </mat-hint>
                </mat-form-field>
                <div class="addbtn">
                  <div (click)="removeRow(kk)" class="border iconTick-1">
                    <mat-icon>remove</mat-icon>
                  </div>
                  <div (click)="addRow()" class="border iconTick-1">
                    <mat-icon>add</mat-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="form-input-container" fxLayout="row" fxLayoutAlign="space-between start">
          <label style="padding-top: 3%;" fxFlex="30%">Description</label>
          <div class="w-100" fxFlex="70%" formArrayName="description">
            <div *ngFor="let languagevalues of description_language;let kk = index">
              <div [formGroupName]="kk" fxLayout="row" class="gap-10" fxLayoutAlign="space-between start">
                <mat-form-field floatLabel="never">
                  <mat-select formControlName="language" name="language">
                    <mat-option *ngFor="let language of languages" [value]="language.language_id">
                      {{ language.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field floatLabel="never">
                  <textarea [errorStateMatcher]="matcher" matInput formControlName="value" name="value"></textarea>
                  <mat-error *ngIf="!groupform.controls.description.controls[kk].controls.value.valid">
                    This field is <strong>required</strong>
                  </mat-error>
                </mat-form-field>
                <div class="addbtn">
                  <div (click)="removeDescriptionRow(kk)" class="border iconTick-1">
                    <mat-icon>remove</mat-icon>
                  </div>
                  <div (click)="addDescriptionRow()" class="border iconTick-1">
                    <mat-icon>add</mat-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="data.parent.is_property_dependent && !data.val" class="form-input-container" fxLayout="row"
          fxLayoutAlign="space-between center">
          <label fxFlex="30%">Property Type</label>
          <mat-form-field floatLabel="never" fxFlex="70%">
            <mat-select formControlName="property_type" multiple>
              <mat-option *ngFor="let property of property_list" [value]="property.property_type_id">
                {{property.property_type_label[api.language] == undefined ? property.property_type_name :
                property.property_type_label[api.language]}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="!groupform.controls.property_type.valid">
              This field is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-input-container" fxLayout="row" fxLayoutAlign="space-between center">
          <label fxFlex="30%">Parent Group</label>
          <mat-form-field floatLabel="never" fxFlex="70%">
            <mat-select formControlName="parent_group" [errorStateMatcher]="matcher">
              <mat-option *ngFor="let grp of group_list" [value]="grp.id">
                {{grp.group_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-input-container" fxLayout="row" fxLayoutAlign="space-between center">
          <label fxFlex="50%">Status</label>
          <mat-checkbox fxFlex="50%" formControlName="status"></mat-checkbox>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" class="dialog-actions">
      <button mat-raised-button type="submit" [disabled]="groupform.invalid || loader"
        class="blue-button">Submit</button>
      <button type="button" mat-raised-button (click)="closeDialog()">Cancel</button>
    </mat-dialog-actions>
  </form>
  <div *ngIf="loader" class='loader-div'>
    <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
  </div>
</div>
